import {
  Button,
  Skeleton,
  Form,
  Input,
  Select,
  Tag,
  Flex,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { useFetchPatientIds } from '../../../assets/reactQuerry/patients';
import { useFetchBeds } from '../../../assets/reactQuerry/beds';
import {
  useEditSample,
  useAddSample,
  useSetTagsToSample,
} from '../../../assets/reactQuerry/samples';
import { useFetchSampleTags } from '../../../assets/reactQuerry/sampleTags';

const { TextArea } = Input;

const tagRender = (props, labels) => {
  const thisLabel = labels.find((label) => label.value === props.value);
  const { label, value, color, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  if (!value) return null;
  return (
    <Tag
      color={thisLabel.color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const dateFormat = 'YYYY/MM/DD';
const SelectDate = (
  <DatePicker
    defaultValue={dayjs('2015/01/01', dateFormat)}
    defaultPickerValue={dayjs('2019/01/01', dateFormat)}
    format={dateFormat}
  />
);

const Step1 = ({ formDataUpload, setFormDataUpload, next }) => {
  const { isLoadingIds, patientsIdName } = useFetchPatientIds('all');
  const { isLoading, data: Beds } = useFetchBeds({});
  const { addSample, newSampleData } = useAddSample();
  const { setTagsToSample } = useSetTagsToSample();
  const { isLoading: isLoadingTags, data: sampleTags } = useFetchSampleTags({});
  const { editSample } = useEditSample();

  if (isLoadingIds || isLoading || isLoadingTags) {
    return <Skeleton />;
  }

  const sampleTagsForFilter = sampleTags.rows.map(({ id, text, color }) => ({
    value: id,
    label: text,
    color,
  }));

  const formDataObject = {};
  formDataUpload.forEach((value, key) => {
    switch (key) {
      case 'BedId':
      case 'PatientId':
        formDataObject[key] = parseInt(value);
        break;
      case 'Sampletags':
        formDataObject[key] = value.split(',').map(Number);
        break;
      case 'originDate':
        formDataObject[key] = dayjs(value);
        break;
      default:
        formDataObject[key] = value;
        break;
    }
  });

  console.log(formDataObject);

  return (
    <>
      <Form
        name='editForm'
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 12,
        }}
        layout='horizontal'
        onFinish={async (values) => {
          const filteredEntries = Object.entries(values).filter(
            ([key, value]) => value
          );

          const formData = formDataUpload;
          for (const [key, value] of filteredEntries) {
            formData.set(key, value);
          }

          let newData = Object.fromEntries(formData);
          //toto predelat
          let {
            Sampletags,
            isUpload,
            file,
            format,
            table,
            sampleId,
            ...sampleData
          } = newData;
          Sampletags = Sampletags?.split(',').map(Number);

          if (!sampleId) {
            const { data: newSample } = await addSample({
              newData: sampleData,
            });
            formData.set('sampleId', newSample.id);
          } else {
            editSample({ sampleId: sampleId, newData: sampleData });
          }

          setFormDataUpload(formData);
          Sampletags &&
            setTagsToSample({
              sampleId: formData.get('sampleId'),
              SampleTagIds: Sampletags,
            });
          next();
        }}
        initialValues={formDataObject}
      >
        <Form.Item name='name' label='Name'>
          <Input />
        </Form.Item>

        <Form.Item
          name='PatientId'
          label='Patient'
          rules={[
            {
              required: true,
              message: 'You have to assign patient!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder='Choose patient'
            optionFilterProp='children'
            options={patientsIdName}
          ></Select>
        </Form.Item>

        <Form.Item
          name='BedId'
          label='Bed'
          rules={[
            {
              required: true,
              message: 'You have to choose a bed file!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder='Choose a bed file'
            optionFilterProp='children'
          >
            {Beds.rows.map((bed) => {
              return (
                <Select.Option key={bed.id} value={bed.id}>
                  {bed.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name='originDate' label='Origin date'>
          <DatePicker
            // defaultValue={dayjs('2015/01/01', dateFormat)}
            // defaultPickerValue={dayjs()}
            format={dateFormat}
          />
        </Form.Item>

        <Form.Item name='comment' label='Comment'>
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item name='Sampletags' label='Sample Tags'>
          <Select
            mode='tags'
            tagRender={(props) => tagRender(props, sampleTagsForFilter)}
            placeholder='Tags Mode'
            options={sampleTagsForFilter}
          />
        </Form.Item>

        <Flex justify='flex-end'>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Flex>
      </Form>
    </>
  );
};
export default Step1;
