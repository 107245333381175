import React from 'react';

export const VariantSvg = () => (
  //   <svg
  //     throwIfNamespace={false}
  //     version='1.1'
  //     x='0px'
  //     y='0px'
  //     viewBox='0 0 100 100'
  //     style={{
  //       //   enableBackground: 'new 0 0 200 100',
  //       width: '20px', // Change width to make it bigger
  //       height: '20px',
  //     }}
  //     xmlSpace='preserve'
  //     id='svg6'
  //     xmlns='http://www.w3.org/2000/svg'
  //     xmlnsI='http://ns.adobe.com/AdobeIllustrator/10.0/'
  //     xmlnsSodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd'
  //     xmlnsInkscape='http://www.inkscape.org/namespaces/inkscape'
  //   >
  //     <defs id='defs6' />
  //     <g id='g5'>
  //       <path
  //         d='M 71.8,60.4 77.1,58 c 1.1,-0.5 1.6,-1.8 1.1,-2.9 C 77.7,54 76.4,53.5 75.3,54 L 70,56.4 c -1.1,0.5 -1.6,1.8 -1.1,2.9 0.4,0.8 1.2,1.3 2,1.3 0.3,0 0.6,-0.1 0.9,-0.2 z'
  //         id='path1'
  //         fill='#FFF'
  //       />
  //       <path
  //         d='m 64.5,52.9 c 0.4,0.4 1,0.7 1.6,0.7 0.6,0 1.1,-0.2 1.5,-0.6 l 4.1,-4 c 0.9,-0.8 0.9,-2.2 0,-3.1 -0.8,-0.9 -2.2,-0.9 -3.1,0 l -4.1,4 c -0.8,0.7 -0.9,2.1 0,3 z'
  //         id='path2'
  //         fill='#FFF'
  //       />
  //       <path
  //         d='m 78.6,64.1 -5.7,0.4 c -1.2,0.1 -2.1,1.1 -2,2.4 0.1,1.2 1,2 2.2,2 0.1,0 0.1,0 0.2,0 L 79,68.5 c 1.2,-0.1 2.1,-1.1 2,-2.4 -0.2,-1.2 -1.2,-2.1 -2.4,-2 z'
  //         id='path3'
  //         fill='#FFF'
  //       />
  //       <path
  //         d='m 63.5,65.9 c -1.2,0.3 -1.8,1.6 -1.5,2.7 1,3.3 1.5,6.7 1.5,10.1 v 11.9 c 0,1.3 -1.1,2.4 -2.4,2.4 -1.3,0 -2.4,-1.1 -2.4,-2.4 V 78.7 c 0,-9.2 -4.2,-17.6 -11.1,-23.2 1.5,-0.8 3,-1.7 4.3,-2.7 2.3,2 4.2,4.2 5.9,6.7 0.7,1 2,1.3 3.1,0.6 1,-0.7 1.3,-2 0.6,-3.1 C 59.8,54.4 57.7,52 55.4,49.9 63.3,42.6 68,32.3 68,21.2 V 9.2 C 68,5.4 64.9,2.4 61.2,2.4 57.6,2.4 54.7,5.2 54.4,8.7 H 32.7 C 32.4,5.2 29.5,2.4 25.9,2.4 c -3.8,0 -6.8,3.1 -6.8,6.8 v 11.9 c 0,11.1 4.7,21.4 12.6,28.7 -7.9,7.3 -12.6,17.6 -12.6,28.8 v 12 c 0,3.8 3.1,6.8 6.8,6.8 3.6,0 6.5,-2.8 6.8,-6.3 h 21.7 c 0.3,3.5 3.2,6.3 6.8,6.3 3.8,0 6.8,-3.1 6.8,-6.8 V 78.7 C 68,74.8 67.4,71 66.3,67.3 65.9,66.2 64.7,65.5 63.5,65.9 Z M 43.5,58 c 2.8,1.9 5.1,4.4 6.8,7.1 H 36.7 c 1.7,-2.7 4,-5.2 6.8,-7.1 z M 32.7,80.4 h 21.5 v 6.4 H 32.7 Z M 32.9,76 c 0.2,-2.2 0.8,-4.4 1.6,-6.4 h 18 c 0.8,2 1.3,4.2 1.6,6.4 z M 43.5,42 c -2.8,-1.9 -5.1,-4.4 -6.8,-7.1 h 13.6 c -1.7,2.7 -4,5.2 -6.8,7.1 z m 9,-11.5 h -18 c -0.8,-2 -1.3,-4.2 -1.6,-6.4 h 21.2 c -0.3,2.2 -0.8,4.3 -1.6,6.4 z M 54.3,19.6 H 32.7 v -6.4 h 21.5 v 6.4 z m -30.8,1.7 v -12 c 0,-1.3 1.1,-2.4 2.4,-2.4 1.3,0 2.4,1.1 2.4,2.4 v 11.9 c 0,9.2 4.2,17.6 11.1,23.2 -1.5,0.8 -2.9,1.7 -4.3,2.7 C 27.8,40.7 23.5,31.4 23.5,21.3 Z m 4.8,69.4 c 0,1.3 -1.1,2.4 -2.4,2.4 -1.3,0 -2.4,-1.1 -2.4,-2.4 v -12 c 0,-13.1 7.3,-24.9 18.9,-30.9 10,-5.1 16.3,-15.3 16.3,-26.6 v -12 c 0,-1.3 1.1,-2.4 2.4,-2.4 1.3,0 2.4,1.1 2.4,2.4 v 12 c 0,13.1 -7.3,24.9 -18.9,30.9 -10,5.1 -16.3,15.3 -16.3,26.6 z'
  //         id='path4'
  //         fill='#FFF'
  //       />
  //     </g>
  //   </svg>
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    width='20px'
    height='20px'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='#FFF'
      stroke='none'
    >
      <path
        d='M1006 4861 c-3 -5 -1 -65 4 -132 51 -628 344 -1251 849 -1806 85 -94
216 -219 345 -331 l38 -33 -48 -42 c-288 -251 -503 -492 -688 -772 -110 -166
-113 -170 -97 -161 6 4 43 7 81 7 103 0 193 -44 249 -122 l28 -38 78 116 c43
64 119 166 169 227 103 128 332 359 458 465 l88 73 92 -78 c193 -162 430 -416
570 -611 288 -400 471 -871 495 -1271 l6 -102 193 0 c106 0 195 4 198 8 8 13
-12 244 -30 347 -120 699 -526 1367 -1168 1923 l-38 33 43 37 c176 149 365
345 515 531 80 98 265 367 280 404 3 9 21 21 39 27 49 16 130 97 156 155 22
48 26 101 18 225 -1 14 17 77 39 140 81 226 130 457 139 658 l6 122 -197 0
-196 0 0 -62 c0 -129 -44 -352 -106 -544 l-36 -110 -42 -13 c-71 -21 -137 -78
-176 -155 -29 -57 -34 -77 -34 -134 1 -39 8 -83 17 -105 l16 -38 -84 -125
c-47 -69 -124 -174 -172 -232 -102 -126 -332 -358 -455 -461 l-88 -73 -92 78
c-193 162 -430 416 -570 611 -289 401 -471 870 -495 1271 l-6 102 -193 0
c-106 0 -195 -4 -198 -9z'
      />
      <path
        d='M4166 3889 c-35 -28 -35 -70 0 -98 24 -19 40 -21 144 -21 104 0 120
2 144 21 17 13 26 30 26 49 0 19 -9 36 -26 49 -24 19 -40 21 -144 21 -104 0
-120 -2 -144 -21z'
      />
      <path
        d='M3990 3480 c-39 -39 -27 -67 73 -167 100 -100 128 -112 167 -73 40
40 28 69 -74 168 -100 99 -127 111 -166 72z'
      />
      <path
        d='M896 1879 c-47 -37 -36 -63 67 -166 101 -101 126 -112 165 -70 39 41
29 63 -71 164 -57 57 -101 93 -114 93 -11 0 -32 -9 -47 -21z'
      />
      <path
        d='M666 1329 c-35 -28 -35 -70 0 -98 24 -19 40 -21 144 -21 104 0 120 2
144 21 17 13 26 30 26 49 0 19 -9 36 -26 49 -24 19 -40 21 -144 21 -104 0
-120 -2 -144 -21z'
      />
      <path
        d='M1157 1057 c-91 -257 -146 -529 -147 -714 l0 -83 195 0 195 0 0 61
c0 130 65 449 119 587 26 66 26 65 -4 60 -38 -8 -123 9 -169 32 -52 28 -91 64
-126 117 l-27 43 -36 -103z'
      />
    </g>
  </svg>
);

export default VariantSvg;
