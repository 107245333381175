import {
  DownOutlined,
  UpOutlined,
  ApiOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { Avatar, Card, List, Space, Skeleton, Result } from 'antd';
import CountUp from 'react-countup';
import { Col, Row, Statistic, Button } from 'antd';
import { Flex, Tooltip } from 'antd';
import {
  useFetchGroups,
  useFetchGroupMembers,
  useFetchMyGroupAccesses,
  useGiveAccess,
  useCancelAccess,
} from '../../../assets/reactQuerry/groups';
import { stringToColour } from '../../../assets/ColorFunc';
import './group.css';

const formatter = (value) => <CountUp end={value} separator=',' />;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const AssociatedGroups = () => {
  const [isAccessible, setIsAccessible] = useState(false);
  const [isGranted, setIsGranted] = useState(false);
  const {
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
    error: errorGroups,
    data: GroupsData,
  } = useFetchGroups({});
  const { giveGroupAccess } = useGiveAccess();
  const { cancelGroupAccess } = useCancelAccess();

  const {
    isLoading: isLoadingGroupMembers,
    isError: isErrorGroupMembers,
    error: errorGroupMembers,
    data: GroupMembers,
  } = useFetchGroupMembers({});

  const {
    isLoading: isLoadingMyGroupAccesses,
    isError: isErrorMyGroupAccesses,
    error: errorMyGroupAccesses,
    data: MyGroupAccesses,
  } = useFetchMyGroupAccesses();

  if (isLoadingGroups || isLoadingGroupMembers || isLoadingMyGroupAccesses)
    return <Skeleton active />;

  if (isErrorGroups)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Omlouvame se, neco se porouchalo'
      />
    );

  // console.log(MyGroupAccesses);
  const groupDataTable = GroupsData.map((group) => {
    const members = GroupMembers.filter((member) => {
      return group.id === member.GroupId;
    });
    const leader = members.find((member) => member.RoleId === 2);
    const isAccessible = MyGroupAccesses.some(
      (access) => access.GroupId === group.id
    );

    // console.log(isAccessible);
    // console.log(group);
    return {
      groupId: group.id,
      href: 'localhost:3000',
      title: `Group ${group.id}: ${group.name}`,
      avatar: (
        <Tooltip title={leader?.fullName || 'No leader'} placement='top'>
          <Avatar
            style={{
              backgroundColor: stringToColour(leader?.firstName || '?'),
            }}
          >
            {leader?.initials || '?'}
          </Avatar>
        </Tooltip>
      ),
      isAccessible: MyGroupAccesses.some(
        (access) => access.GroupId === group.id
      ),
      isGranted: MyGroupAccesses.some(
        (access) => access.AccessedGroupId === group.id
      ),
      content: (
        <Avatar.Group
          maxCount={12}
          size='large'
          maxStyle={{
            color: '#f56a00',
            backgroundColor: '#fde3cf',
          }}
        >
          {members.map((member) => {
            return (
              <Tooltip key={member.id} title={member.fullName} placement='top'>
                <Avatar
                  style={{
                    backgroundColor: stringToColour(member.firstName),
                  }}
                >
                  {member.initials}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>
      ),
      description: group.description,
    };
  });

  return (
    <List
      itemLayout='vertical'
      size='large'
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
        defaultPageSize: 5,
      }}
      dataSource={groupDataTable}
      renderItem={(item) => (
        <>
          <List.Item
            key={item.title}
            actions={[
              <>
                <Flex justify='center' style={{ paddingBottom: '10px' }}>
                  <Space>
                    <IconText
                      icon={() => {
                        return (
                          <DownOutlined
                            style={{
                              color: item.isGranted ? 'green' : 'red',
                              fontSize: '2em',
                            }}
                          />
                        );
                      }}
                      text={
                        item.isGranted ? (
                          <span style={{ color: 'green' }}>
                            {' '}
                            Can access us{' '}
                          </span>
                        ) : (
                          <span style={{ color: 'red' }}>
                            {' '}
                            Cannot access us{' '}
                          </span>
                        )
                      }
                      key='isGranted'
                    />
                    {item.isGranted ? (
                      <Space>
                        <Button
                          danger
                          icon={<LockOutlined style={{ color: 'red' }} />}
                          // onClick={() => setIsGranted((granted) => !granted)}
                          onClick={() => cancelGroupAccess(item.groupId)}
                        >
                          Prohibit access
                        </Button>
                      </Space>
                    ) : (
                      <Space>
                        <Button
                          icon={<UnlockOutlined style={{ color: 'green' }} />}
                          style={{ color: 'green', borderColor: 'green' }}
                          // onClick={() => setIsGranted((granted) => !granted)}
                          onClick={() => giveGroupAccess(item.groupId)}
                        >
                          Grant access
                        </Button>
                      </Space>
                    )}
                  </Space>
                </Flex>
                <Flex justify='center'>
                  <Space>
                    <IconText
                      icon={() => {
                        return (
                          <UpOutlined
                            style={{
                              color: item.isAccessible ? 'green' : 'red',
                              fontSize: '2em',
                            }}
                          />
                        );
                      }}
                      text={
                        item.isAccessible ? (
                          <span style={{ color: 'green' }}>Accessible </span>
                        ) : (
                          <span style={{ color: 'red' }}> Access denied</span>
                        )
                      }
                      key='isAccessible'
                    />
                    {item.isAccessible ? (
                      <Space>
                        <Button
                          danger
                          icon={<LockOutlined style={{ color: 'red' }} />}
                          onClick={() =>
                            setIsAccessible((accessible) => !accessible)
                          }
                        >
                          Remove access
                        </Button>
                      </Space>
                    ) : (
                      <Space>
                        <Button
                          icon={<UnlockOutlined style={{ color: 'green' }} />}
                          style={{ color: 'green', borderColor: 'green' }}
                          onClick={() =>
                            setIsAccessible((accessible) => !accessible)
                          }
                        >
                          Request access
                        </Button>
                      </Space>
                    )}
                  </Space>
                </Flex>
              </>,
            ]}
            extra={
              <Col>
                <Row gutter={24}>
                  <Statistic
                    title='Patients'
                    value={112893}
                    precision={2}
                    formatter={formatter}
                  />
                </Row>
                <Row gutter={24}>
                  <Statistic
                    title='Samples'
                    value={112893}
                    precision={2}
                    formatter={formatter}
                  />
                </Row>
              </Col>
            }
          >
            <List.Item.Meta
              avatar={item.avatar}
              title={<a href={item.href}>{item.title}</a>}
              description={item.description}
            />
            {item.content}
          </List.Item>
        </>
        // </Card>
      )}
    />
  );
};
export default AssociatedGroups;
