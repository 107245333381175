/// THIS IS RESTRICTED PAGE TO GROUP LEADERS
import { Anchor, Row, Col, Card, Divider } from 'antd';
import TeamLogs from './TeamLogs';
import MyGroup from './MyGroup';
import GroupMembers from './Members';
import SampleTags from './SampleTags';
import AssociatedGroups from './AssociatedGroups';
import VarComments from './VarComments';

function PatientDetail() {
  return (
    <Row>
      <Col span={20}>
        {/* <Card > */}

        <div id='varcomments'>
          <Divider orientation='left'>Variant notes</Divider>
          <VarComments />
        </div>

        <div id='mygroup'>
          <Divider orientation='left'>My Group</Divider>
          <MyGroup />
        </div>

        <div id='members'>
          <Divider orientation='left'>Group Members</Divider>
          <GroupMembers />
        </div>

        <div id='teamlogs'>
          <Divider orientation='left'>Group Logs</Divider>
          <TeamLogs />
        </div>

        <div id='assocgroups'>
          <Divider orientation='left'>Associated Groups</Divider>
          {/* <Card bordered={false}> */}
          <AssociatedGroups />
          {/* </Card> */}
        </div>

        <div id='tags'>
          <Divider orientation='left'>Sample Tags</Divider>
          <SampleTags />
        </div>
      </Col>

      <Col span={4}>
        <Anchor
          items={[
            {
              key: 'part-1',
              href: '#mygroup',
              title: 'My Group',
            },
            {
              key: 'part-1.5',
              href: '#members',
              title: 'Members',
            },
            {
              key: 'part-2.5',
              href: '#teamlogs',
              title: 'Group Logs',
            },
            {
              key: 'part-2',
              href: '#assocgroups',
              title: 'Associated Groups',
            },

            {
              key: 'part-3',
              href: '#tags',
              title: 'Sample Tags',
            },
            {
              key: 'part-4',
              href: '#varcomments',
              title: 'Variant Notes',
            },
          ]}
        />
      </Col>
    </Row>
  );
}
export default PatientDetail;
