import { Descriptions, Empty, Tag, Image, Button, Skeleton } from 'antd';
import { useFetchPatientIds } from '../../../assets/reactQuerry/patients';
import { useFetchBeds } from '../../../assets/reactQuerry/beds';
import { UploadOutlined } from '@ant-design/icons';
import { useFetchSampleTags } from '../../../assets/reactQuerry/sampleTags';
import { usePushTableToDB } from '../../../assets/reactQuerry/samples';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

const SubmitSample = ({ formDataUpload, next, prev }) => {
  const Patientid = formDataUpload.get('PatientId');
  const navigate = useNavigate();

  const { isLoadingIds, patientsIdName } = useFetchPatientIds(Patientid);
  const { isLoading, isError, data: Beds } = useFetchBeds({});
  const { isLoading: isLoadingTags, data: sampleTags } = useFetchSampleTags({});
  const { pushTableToDB } = usePushTableToDB();

  if (isLoadingIds || isLoading || isLoadingTags) {
    return <Skeleton />;
  }
  const formDataObject = {};
  formDataUpload.forEach((value, key) => {
    switch (key) {
      case 'BedId':
      case 'PatientId':
        formDataObject[key] = parseInt(value);
        break;
      case 'Sampletags':
        formDataObject[key] = value.split(',').map(Number);
        break;
      default:
        formDataObject[key] = value;
        break;
    }
  });

  const filteredTags = formDataObject.Sampletags
    ? sampleTags.rows.filter((tag) =>
        formDataObject.Sampletags.includes(tag.id)
      )
    : [];
  const items = [
    {
      key: '0',
      label: 'ID',
      children: formDataObject.sampleId,
      width: '10%',
    },
    {
      key: '1',
      label: 'Name',
      children: formDataObject.name,
    },
    {
      key: '2',
      label: 'Patient',
      children: patientsIdName[0]?.label,
    },
    {
      key: '2.5',
      label: 'Origin date',
      children: (
        <span>
          {formDataObject.originDate &&
            dayjs(formDataObject.originDate).format('YYYY/MM/DD')}
        </span>
      ),
    },

    {
      key: '3',
      label: 'Comment',
      children: formDataObject.comment,
    },

    {
      key: '7',
      label: 'Table',
      children: formDataObject.isUpload === 'true' && (
        <>
          <UploadOutlined /> {formDataObject.file?.name}
        </>
      ),
    },
    {
      key: '4',
      label: 'Sample tags',
      children: filteredTags.map((tag) => {
        const { color, text, id } = tag;
        return (
          <Tag color={color} key={id}>
            {text.toUpperCase()}
          </Tag>
        );
      }),
    },
  ];

  return (
    <div className='table'>
      <Descriptions
        title={`Double check`}
        layout='vertical'
        bordered={true}
        column={{ sm: 1, md: 2, lg: 4, xxl: 6 }}
        items={items}
      />
      <div className='centerButton'>
        <Button
          onClick={(e) => {
            pushTableToDB(formDataObject.sampleId);
            navigate('/app/samples');
          }}
          type='primary'
        >
          Submit sample
        </Button>
      </div>
    </div>
  );
};
export default SubmitSample;
