import { useState } from 'react';
import authFetch from '../../../assets/axios/authFetch';
import { Flex, Select, Button, Checkbox, Form, App, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useEditSample } from '../../../assets/reactQuerry/samples';
const { Dragger } = Upload;

const Step2 = ({ formDataUpload, setFormDataUpload, next, prev }) => {
  const { editSample } = useEditSample();

  const [isUpload, setIsUpload] = useState(
    formDataUpload.get('isUpload') === 'true'
  );
  const [isVcf, setIsVcf] = useState(true);
  const { message } = App.useApp();

  const props = {
    name: 'file',
    maxCount: 1,
    defaultFileList: formDataUpload.get('file')
      ? [formDataUpload.get('file')]
      : [],
    beforeUpload: (file) => {
      console.log(file);
      const isCorrectUploaded = isVcf
        ? file.name.endsWith('.vcf.gz') && file.type === 'application/x-gzip'
        : file.type === 'text/csv';
      if (!isCorrectUploaded) {
        isVcf
          ? message.error(`${file.name} is not a .vcf.gz file`)
          : message.error(`${file.name} is not a .csv file`);
      }
      return isCorrectUploaded || Upload.LIST_IGNORE;
    },
    customRequest: async (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      try {
        const formData = new FormData();
        const format = isVcf ? 'VEP' : 'CLC';
        formData.set(format, file);
        const config = {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (event) => {
            // console.log((event.loaded / event.total) * 100);
            onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          },
        };

        const sampleId = formDataUpload.get('sampleId');
        await authFetch.post(`/samples/${sampleId}/upload`, formData, config);
        const newFormData = formDataUpload;
        newFormData.set('file', file);
        setFormDataUpload(newFormData);
        message.success('Variants data table uploaded');
        onSuccess(file);
      } catch (err) {
        console.log(err);
        const error = new Error(err.response.data.error);
        message.error(err.response.data.error);
        onError(error);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const formDataObject = {};
  formDataUpload.forEach((value, key) => {
    formDataObject[key] = value;
  });

  const updateForm = (values) => {
    console.log(values);
    const filteredEntries = Object.entries(values).filter(
      ([_, value]) => value
    );
    console.log(filteredEntries);
    const formData = formDataUpload;
    for (const [key, value] of filteredEntries) {
      formData.set(key, value);
    }
    let { isUpload, file, format, table, sampleId } =
      Object.fromEntries(formData);
    if (isUpload === 'true') {
      editSample({
        sampleId,
        newData: { table_type: format, table_path: file?.name },
      });
    }
    console.log(Object.fromEntries(formData));

    setFormDataUpload(formDataUpload);
  };

  const onFinishClb = (values) => {
    updateForm(values);
    next();
  };

  return (
    <>
      <Flex justify='center' style={{ marginTop: '3rem' }}>
        <Checkbox
          checked={isUpload}
          onChange={(e) => {
            const formData = formDataUpload;
            formData.set('isUpload', e.target.checked);
            setFormDataUpload(formDataUpload);
            setIsUpload(e.target.checked);
          }}
          style={{ marginBottom: 16, fontSize: '1.2rem' }}
        >
          Upload variants data table?
        </Checkbox>
      </Flex>

      <Form
        name='uploadTable'
        disabled={!isUpload}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 12,
        }}
        layout='horizontal'
        onFinish={onFinishClb}
        initialValues={formDataObject}
      >
        <Form.Item name='format' label='Format'>
          <Select
            style={{
              width: 120,
            }}
            onChange={(status) => {
              setIsVcf(status === 'VEP');
            }}
            options={[
              {
                value: 'VEP',
                label: 'VCF file',
              },
              {
                value: 'CLC',
                label: 'CLC file',
              },
            ]}
          />
        </Form.Item>
        <Form.Item name='table' label='Table Upload'>
          <Dragger {...props}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              {isVcf ? (
                <>
                  Click or drag file a <b>.vcf.gz</b> to this area to upload
                </>
              ) : (
                <>
                  Click or drag file a <b>CLC table</b> to this area to upload
                </>
              )}
            </p>
          </Dragger>
        </Form.Item>
        <Flex justify='space-between'>
          <Button disabled={false} onClick={prev}>
            Previous
          </Button>
          <Button type='primary' disabled={false} htmlType='submit'>
            Next
          </Button>
        </Flex>
      </Form>
    </>
  );
};
export default Step2;
