import { Button, Checkbox, Form, Input, Skeleton, Divider, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import './index.css'
import { useLogin } from '../../assets/reactQuerry/login'

const onFinishFailed = (errorInfo) => {
  console.log(errorInfo)
  message.error('Vyplnte spravne prihlasovaci data')
}
const LoginPage = () => {
  const { isLoading, logIn } = useLogin()

  const onFinish = (values) => {
    logIn({
      logindata: {
        email: values.email,
        password: values.password,
      },
      remember: values.remember,
    })
  }

  return (
    <Skeleton loading={isLoading}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={(values, setToken) => onFinish(values, setToken)}
        onFinishFailed={onFinishFailed}
      >
        <Divider orientation="left">Prihlaseni</Divider>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Zadejte svuj email!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="jan.novak@gmail.com"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Zadejte sve heslo',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Heslo1234"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Neodhlasuj me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="">
            Zapomenute heslo
          </a>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Prihlasit
          </Button>
          nebo <a href="">se zaregistrujte</a>
        </Form.Item>
      </Form>
    </Skeleton>
  )
}

export default LoginPage
