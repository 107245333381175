import {
  Descriptions,
  Empty,
  Button,
  Skeleton,
  Result,
  Avatar,
  Tooltip,
} from 'antd';

import { useFetchGroups } from '../../../assets/reactQuerry/groups';
import { useFetchGroupMembers } from '../../../assets/reactQuerry/groups';
import { Link } from 'react-router-dom';
import { stringToColour } from '../../../assets/ColorFunc';

const PatientDescription = () => {
  const { isLoading, isError, data } = useFetchGroups({ id: 'my' });
  const {
    isLoading: isLoadingIds,
    isError: isErrorIds,
    data: groupMembers,
  } = useFetchGroupMembers({ groupId: 'my' });

  if (isLoading || isLoadingIds) return <Skeleton />;

  if (isError || isErrorIds)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Omlouvame se, neco se porouchalo'
      />
    );

  if (!data) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nic nenalezeno!</span>}
      />
    );
  }

  const items = [
    {
      key: '1',
      label: 'Name',
      children: data[0].name,
    },
    {
      key: '2',
      label: 'Description',
      children: data[0].description,
    },
    {
      key: '3',
      label: 'Group members',
      children: (
        <Avatar.Group
          maxCount={3}
          size='large'
          maxStyle={{
            color: '#f56a00',
            backgroundColor: '#fde3cf',
          }}
        >
          {groupMembers.map((user) => {
            return (
              <Tooltip key={user.id} title={user.fullName} placement='top'>
                <Avatar
                  style={{
                    backgroundColor: stringToColour(user.firstName),
                  }}
                >
                  {user.initials}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>
      ),
    },
  ];
  return (
    <div className='table'>
      <Descriptions
        layout='vertical'
        bordered={true}
        column={{ sm: 1, md: 2, lg: 4, xxl: 6 }}
        items={items}
      />
      <div className='centerButton'>
        <Button type='primary'>
          <Link to='edit' relative='path'>
            Edit
          </Link>
        </Button>
      </div>
    </div>
  );
};
export default PatientDescription;
