import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  Form,
  Table,
  Popconfirm,
  Typography,
  Result,
  Space,
  Flex,
  Button,
  Skeleton,
  App,
  Empty,
} from 'antd';
import EditableCell from './EditableCell';
import getPatientColumns from './tableColumnsPatient';
import { Link } from 'react-router-dom';
import {
  usePrefetchPatients,
  useFetchPatients,
  useEditPatient,
  useFetchPatientIds,
  useAddPatient,
  useDeletePatient,
} from '../../assets/reactQuerry/patients';
import { useSetTagsForPatientId } from '../../assets/reactQuerry/patientTags';

import reorderParams from '../../assets/reorderParams';

export const loader = (queryClient) => async () => {
  const data = await queryClient.ensureQueryData(
    usePrefetchPatients({ sort: 'id:DESC' })
  );
  return data.count;
};

const PatientsTable = () => {
  const count = useLoaderData();
  const [SearchOptions, setSearchOptions] = useState('');
  const { isLoadingIds, patientsIdName } = useFetchPatientIds('all');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const { editPatient } = useEditPatient();
  const { addPatient, newPatientData } = useAddPatient();
  const { message } = App.useApp();
  const { deletePatient } = useDeletePatient();
  const { setTagsForPatientId } = useSetTagsForPatientId();
  const isEditing = (record) => record.id === editingKey;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchOptions((old) => {
      const updatedOptions = { ...old, [dataIndex]: selectedKeys };
      setTableParams((prevTableParams) => ({
        //needs to be update filter here to have the latest updatedOptions
        ...prevTableParams,
        filters: { ...prevTableParams.filters, ...updatedOptions },
      }));
      return updatedOptions; // Return the updated options
    });
  };

  const handleReset = (dataIndex) => {
    if (dataIndex) {
      setSearchOptions((old) => {
        const updatedOptions = { ...old, [dataIndex]: null };
        setTableParams((prevTableParams) => ({
          //needs to be update filter here to have the latest updatedOptions
          ...prevTableParams,
          filters: { ...updatedOptions },
        }));
        return updatedOptions; // Return the updated options
      });
    } else {
      setSearchOptions('');
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        filters: {},
      }));
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    field: 'id', //for not refetching, default descend sort id
    order: 'DESC',
  });

  const params = reorderParams(tableParams, handleReset);

  const {
    isLoading,
    isError,
    error,
    data: dataPatients,
  } = useFetchPatients(params);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      ...sorter,
    }));
  };

  useEffect(() => {
    if (dataPatients && dataPatients.count !== tableParams.pagination.total) {
      handleTableChange({
        total: dataPatients.count,
      });
    }
  }, [dataPatients]); // Only run this effect when `data` changes

  const handleAdd = async () => {
    //THIS CHECK
    const data = await addPatient({ newData: { name: 'Xxxx' } });
    setTimeout(() => setEditingKey(data?.data.id), 200);
  };

  if (isError) {
    message.error('Unable to load data...');
    return (
      <Result
        status='500'
        title='500'
        subTitle='Sorry, something went wrong...'
      />
    );
  }

  if (isLoading || isLoadingIds) {
    return <Skeleton />;
  }

  //   if (!dataPatients?.data.length) {
  //     return (
  //       <Empty
  //         imageStyle={{ height: 100 }}
  //         description={<span>Nothing found!</span>}
  //       />
  //     );
  //   }

  const PatientColumns = getPatientColumns(
    SearchOptions,
    handleSearch,
    handleReset,
    patientsIdName
  );

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    form.resetFields();
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const { PatientTags2, ...patientData } = row;
      editPatient({ patientId: key, newData: patientData });

      PatientTags2 &&
        setTagsForPatientId({
          patientId: key,
          PatientTags: PatientTags2,
        });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error(`Data not uploaded ${errInfo}`); // Display an error message
    }
  };

  const editCols = [
    ...PatientColumns,
    {
      dataIndex: 'operation',
      width: 160,
      fixed: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                display: 'block',
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <Typography.Link type='danger'>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Flex wrap='wrap' gap='small'>
            <Link to={`${record.id}`} relative='path'>
              Detail
            </Link>
            <Typography.Link
              type='warning'
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>

            <Popconfirm
              danger
              title='Sure to delete?'
              onConfirm={() => {
                deletePatient(record.id);
              }}
            >
              <Typography.Link type='danger'>Delete</Typography.Link>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = editCols.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Flex gap='middle' justify='center' align='center'>
        <Space style={{ padding: '1rem' }}>
          <Button onClick={handleAdd} type='primary'>
            New subject
          </Button>
          <Button onClick={() => handleReset()}>Clear filters</Button>
        </Space>
      </Flex>

      <Form form={form} component={false}>
        <Table
          loading={isLoading}
          rowKey={(record) => record.id}
          components={{
            body: {
              cell: (cell) => (
                <EditableCell
                  {...cell}
                  data={[...patientsIdName, { value: null, label: '-' }]}
                />
              ),
            },
          }}
          bordered
          dataSource={dataPatients.data}
          columns={mergedColumns}
          rowClassName='editable-row'
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          scroll={{
            x: '1300',
          }}
          // pagination={{
          //   onChange: cancel,
          // }}
        />
      </Form>
    </div>
  );
};
export default PatientsTable;
