import { Form, Table, Tooltip, Result } from 'antd';
import { useFetchTranscripts } from '../../../assets/reactQuerry/variants';

const Annot_VEPs_fields = [
  'AF',
  'AFR_AF',
  'AMR_AF',
  'APPRIS',
  'Amino_acids',
  'BAM_EDIT',
  'BIOTYPE',
  'CANONICAL',
  'CCDS',
  'CDS_position',
  'CLIN_SIG',
  'Codons',
  'Consequence',
  'DISTANCE',
  'DOMAINS',
  'EAS_AF',
  'ENSP',
  'EUR_AF',
  'EXON',
  'Existing_variation',
  'FLAGS',
  'Feature',
  'Feature_type',
  'GENE_PHENO',
  'GIVEN_REF',
  'Gene',
  'HGNC_ID',
  'HGVS_OFFSET',
  'HGVSc',
  'HGVSp',
  'HIGH_INF_POS',
  'IMPACT',
  'INTRON',
  'MANE_PLUS_CLINICAL',
  'MANE_SELECT',
  'MAX_AF',
  'MAX_AF_POPS',
  'MOTIF_NAME',
  'MOTIF_POS',
  'MOTIF_SCORE_CHANGE',
  'PHENO',
  'PUBMED',
  'PolyPhen',
  'Protein_position',
  'REFSEQ_MATCH',
  'REFSEQ_OFFSET',
  'SAS_AF',
  'SIFT',
  'SOMATIC',
  'SOURCE',
  'STRAND',
  'SWISSPROT',
  'SYMBOL',
  'SYMBOL_SOURCE',
  'TRANSCRIPTION_FACTORS',
  'TREMBL',
  'TSL',
  'UNIPARC',
  'UNIPROT_ISOFORM',
  'USED_REF',
  'VARIANT_CLASS',
  //   'VariantId',
  'cDNA_position',
  'gnomADe_AF',
  'gnomADe_AFR_AF',
  'gnomADe_AMR_AF',
  'gnomADe_ASJ_AF',
  'gnomADe_EAS_AF',
  'gnomADe_FIN_AF',
  'gnomADe_NFE_AF',
  'gnomADe_OTH_AF',
  'gnomADe_SAS_AF',
  'gnomADg_AF',
  'gnomADg_AFR_AF',
  'gnomADg_AMI_AF',
  'gnomADg_AMR_AF',
  'gnomADg_ASJ_AF',
  'gnomADg_EAS_AF',
  'gnomADg_FIN_AF',
  'gnomADg_MID_AF',
  'gnomADg_NFE_AF',
  'gnomADg_OTH_AF',
  'gnomADg_SAS_AF',
  'miRNA',
];

const VEP_cols = Annot_VEPs_fields.map((field) => {
  const capitalizedStr = field.charAt(0).toUpperCase() + field.slice(1);
  return {
    title: capitalizedStr,
    dataIndex: field,
    render: (_, line) => {
      let replacedString = line[field].replace(/&/g, ',');

      return (
        <Tooltip placement='topLeft' title={replacedString}>
          {replacedString}
        </Tooltip>
      );
    },
  };
});

const ExpandedTranscriptsRender = ({ record, subform }) => {
  const { isLoading, isError, data } = useFetchTranscripts({
    variantId: record.id,
  });

  if (isError) {
    return (
      <Result
        status='500'
        title='500'
        subTitle='Sorry, something went wrong...'
      />
    );
  }

  return (
    <Form form={subform} component={false}>
      <Table
        rowKey={(record) => record.id}
        loading={isLoading}
        bordered
        scroll={{
          x: '1300',
        }}
        size='small'
        dataSource={data}
        columns={VEP_cols}
        rowClassName='editable-row'
      />
    </Form>
  );
};

export default ExpandedTranscriptsRender;
