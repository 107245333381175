import { Navigate, Outlet } from 'react-router-dom'
import { useSessionContext } from './SessionContextProvider'
import ErrorMessage from './ErrorMessage'

const ProtectedRoute = ({ children }) => {
  const { token } = useSessionContext()
  console.log('protected route')

  if (!token) {
    return <Navigate to={'/login'} replace />
  }

  return children
}

const ProtectedSubRoutes = ({ children, redirectPath = '/login' }) => {
  const { token } = useSessionContext()

  if (!token) {
    return (
      <>
        <ErrorMessage />
        <Navigate to={redirectPath} />
        {/* <Unauthorized></Unauthorized> */}
      </>
    )
  }

  return children ? children : <Outlet />
}
export { ProtectedRoute, ProtectedSubRoutes }
