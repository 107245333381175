import React, { useState } from 'react';
import { Button, Modal, message, Upload, Table } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useUploadBatchVarComment } from '../../../../assets/reactQuerry/varComments';
const { Dragger } = Upload;

const columns = [
  {
    key: '1',
    dataIndex: 'Chr',
    title: 'Chr',
  },
  {
    key: '2',
    dataIndex: 'Pos_Hg38',
    title: 'Pos_Hg38',
  },
  {
    key: '3',
    dataIndex: 'Ref',
    title: 'Ref',
  },
  {
    key: '4',
    dataIndex: 'Alt',
    title: 'Alt',
  },
  {
    key: '5',
    dataIndex: 'Gene',
    title: 'Gene',
  },
  {
    key: '6',
    dataIndex: 'cName',
    title: 'cName',
  },
  {
    key: '7',
    dataIndex: 'pName',
    title: 'pName',
  },
  {
    key: '8',
    dataIndex: 'Transcript',
    title: 'Transcript',
  },
  {
    key: '9',
    dataIndex: 'Classification',
    title: 'Classification',
  },
  {
    key: '10',
    dataIndex: 'Comment',
    title: 'Comment',
  },
];

const dataSource = [
  {
    Chr: '1',
    Pos_Hg38: '55057360',
    Ref: 'A',
    Alt: 'C',
    Gene: 'PCSK9',
    cName: 'c.1026A>C',
    pName: 'p.(Gln342Gln)',
    Transcript: 'NM_001145445.1',
    Classification: 'Benign',
    Comment: 'bezproblemova varianta',
  },
];

const formData = new FormData();

const VarCommentUpload = () => {
  const { uploadBatchVarComment, isLoading, isPending } =
    useUploadBatchVarComment();
  const [fileList, setFileList] = useState([]);

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    try {
      const response = await uploadBatchVarComment({ table: formData });
      console.log(response);
    } catch (e) {
      console.log(e);
    }
    setFileList([]);

    setOpen(false);
    // }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setFileList([]);

    setOpen(false);
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,

    beforeUpload(file) {
      const isCsv = file.type === 'text/csv';
      if (!isCsv) {
        message.error(`${file.name} is not a csv file!`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    customRequest(options) {
      const { onSuccess, onError, file, onProgress } = options;
      try {
        formData.set('file', file);
        setFileList([file]);
        onSuccess(file);
      } catch (err) {
        console.log(err);
        const error = new Error(err.response.statusText);
        message.error(err.response.data);
        onError(error);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <Button type='primary' onClick={showModal}>
        <UploadOutlined /> Upload batch
      </Button>
      <Modal
        title='Upload Variants Notes File'
        open={open}
        onOk={handleOk}
        confirmLoading={isPending}
        onCancel={handleCancel}
        width={1080}
      >
        <p>Template format:</p>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        <p>
          Classification can be <b>Benign</b>, <b>Likely Benign</b>, <b>VUS</b>,{' '}
          <b>Likely Pathogenic</b> or <b>Pathogenic</b>
        </p>

        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Click or drag file to this area to upload
          </p>
          <p className='ant-upload-hint'>
            The file to be uploaded has to be <b>.csv</b>
          </p>
        </Dragger>
      </Modal>
    </>
  );
};
export default VarCommentUpload;
