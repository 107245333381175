import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const ID = 'all';
const LIMIT = 10;
const OFFSET = 0;
const SORT = '';
const FILTERS = {};

export const useFetchPatients = ({
  id = '',
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
  full = true,
  isEnabled = true,
}) => {
  // console.log(limit)
  // console.log(sort)
  const { isLoading, data, isError, error, isFetchedAfterMount } = useQuery({
    queryKey: ['patients', id, limit, offset, sort, filters],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;
      console.log(
        `/patients?patientId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      const { data } = await authFetch.get(
        `/patients?patientId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      // return data
      return full ? data : data.data[0];
    },
    enabled: isEnabled,
  });
  console.log(
    `/patients?patientId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
      filters
    )}`
  );
  return { isLoading, isError, error, data, isFetchedAfterMount };
};

export const usePrefetchPatients = ({
  id = '',
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
}) => {
  return {
    queryKey: ['patients', id, limit, offset, sort, filters],
    queryFn: async () => {
      const response = await authFetch.get(
        `/patients?patientId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      console.log(
        `/patients?patientId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      return response.data; // Adjust this based on the actual structure of your API response
    },
  };
};

export const useFetchPatientImg = (id, imgName) => {
  const {
    isLoading: isLoadingImg,
    data: imgData,
    isError: isErrorImg,
    error: errorImg,
  } = useQuery({
    queryKey: ['imgs', id, imgName],
    queryFn: async ({ queryKey }) => {
      const [_key, id] = queryKey;
      if (!id || !imgName) {
        throw new Error('ID or image name is missing');
      }
      const response = await authFetch.get(`/patients/${id}/image/${imgName}`, {
        responseType: 'arraybuffer',
      });
      const base64Data = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      const data = {
        uuid: imgName,
        name: imgName,
        thumbUrl: `data:image/png;base64,${base64Data}`,
      };
      return data;
    },
  });
  return { isLoadingImg, imgData, isErrorImg, errorImg };
};

export const useFetchPatientIds = (id) => {
  const {
    isLoading: isLoadingIds,
    data,
    isError,
    error,
  } = useQuery({
    queryKey: ['patientIds', id],
    queryFn: async ({ queryKey }) => {
      const [_key, id] = queryKey;
      const { data } = await authFetch.get(`/patients/ids/${id}`);
      return data;
    },
  });
  return { isLoadingIds, isError, error, patientsIdName: data };
};

export const useEditPatient = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: editPatient } = useMutation({
    mutationFn: ({ patientId, newData }) => {
      return authFetch.patch(
        `/patients/${patientId}`,
        newData
        // , {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   // Other headers as needed
        // },}
      );
    },
    onSuccess: (_, variables) => {
      const { patientId } = variables;
      queryClient.invalidateQueries({
        queryKey: ['patients'],
      });
      message.success('Subject edited');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('Error occurred while editing subject :' + error);
    },
  });
  return { editPatient };
};

export const useAddPatient = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: addPatient, data: newPatientData } = useMutation({
    mutationFn: ({ newData }) => {
      return authFetch.post(`/patients/`, { ...newData });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patients'] });
      message.success('New subject was created!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error('Error occurred while creating new subject! :' + error);
    },
  });

  return { addPatient, newPatientData };
};

export const useDeletePatient = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: deletePatient, isLoading: deletePatientLoading } =
    useMutation({
      mutationFn: (patientId) => {
        return authFetch.delete(`patients/${patientId}`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['patients'] });
        message.success('Subject was deleted!');
      },
      onError: ({
        response: {
          data: { error },
        },
      }) => {
        message.error('Error occurred while deleting subject! :' + error);
      },
    });
  return { deletePatient, deletePatientLoading };
};

export const useFetchFamilyTree = (id) => {
  const {
    isLoading,
    data: familyData,
    isError,
    error,
  } = useQuery({
    queryKey: ['familyTree', id],
    queryFn: async ({ queryKey }) => {
      const [_key, id] = queryKey;

      const { data } = await authFetch.get(`/patients/${id}/family`);
      return data;
    },
  });
  return { isLoading, isError, error, familyData };
};
