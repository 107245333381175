import { Form, Input, Select, Tag, DatePicker } from 'antd';
import dayjs from 'dayjs';

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  data,
  children,
  ...restProps
}) => {
  const { patientsIdName, sampleTags } = data;

  const sampleTagsForFilter = sampleTags.rows.map(({ id, text, color }) => ({
    value: id,
    label: text,
    color,
    // label: { text, color },
  }));

  const tagRender = (props, labels) => {
    const thisLabel = labels.find((label) => label.value === props.value);
    const { label, value, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (!value) return null;
    return (
      <Tag
        color={thisLabel.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const SelectFiltPatient = (
    <Select
      showSearch
      placeholder='Choose a patient'
      optionFilterProp='children'
      filterOption={filterOption}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      options={patientsIdName}
    />
  );

  const dateFormat = 'YYYY/MM/DD';
  const SelectDate = (
    <DatePicker
      //   defaultValue={dayjs('2015/01/01', dateFormat)}
      //   defaultPickerValue={dayjs('2015/01/01', dateFormat)}
      format={dateFormat}
    />
  );

  const SelectSampleTags = (
    <Select
      mode='tags'
      tagRender={(props) => tagRender(props, sampleTagsForFilter)}
      // defaultValue={'brym'}
      // defaultValue={record?.tags ?? []}
      // style={{ width: '100%' }}
      placeholder='Tags Mode'
      options={sampleTagsForFilter}
    />
  );

  let inputNode;
  let rule;

  switch (dataIndex) {
    case 'originDate':
      dataIndex = 'originDate';
      inputNode = SelectDate;
      break;

    case 'PatientId':
      // dataIndex = 'originDastse';

      inputNode = SelectFiltPatient;

      break;
    case 'SampleTags':
      dataIndex = 'SampleTags';
      inputNode = SelectSampleTags;
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: '-10px -5px -10px -5px',
            padding: 0,
          }}
          rules={rule}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
