import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const LIMIT = 20;
const OFFSET = 0;
const SORT = '';
const FILTERS = '';

export const useSetTagsForPatientId = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: setTagsForPatientId } = useMutation({
    mutationFn: ({ patientId, PatientTags }) => {
      console.log(patientId);

      console.log(PatientTags);
      return authFetch.patch(`/patienttag/${patientId}`, { PatientTags });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['patients'],
      });
      message.success('Sample tag successfully edited');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { setTagsForPatientId };
};
