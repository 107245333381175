import { Tooltip, Tag } from 'antd';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import ColumnSearchProps from '../../assets/getColumnSearchProps';

export default (SearchOptions, handleSearch, handleReset, patientsIdName) => {
  console.log(patientsIdName);
  const FilterOptions = {
    sex: [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
    ],
    motherId: [...patientsIdName, { value: null, label: 'null' }],
    fatherId: [...patientsIdName, { value: null, label: 'null' }],
    // PatientTags: '',
  };
  const ColumnsSearchPropsDefault = {
    handleSearch,
    handleReset,
    FilterOptions,
    SearchOptions,
  };

  return [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 80,
      editable: false,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'name',
      }),

      ellipsis: {
        showTitle: false,
      },
      render: (text) =>
        SearchOptions?.name ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.name[0]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
    },
    {
      title: 'Phenotype',
      dataIndex: 'phenotype',
      width: 250,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'phenotype',
      }),
      render: (text) =>
        SearchOptions?.phenotype ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.phenotype[0]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      width: 80,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'sex',
      }),
    },
    {
      title: 'Mother',
      dataIndex: 'motherId',
      width: 120,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'motherId',
      }),
      render: (_, line) =>
        line.mother ? (
          <Link to={`${line.mother.id}`} relative='path'>
            {line.mother.name}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Father',
      dataIndex: 'fatherId',
      width: 120,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'fatherId',
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (_, line) =>
        line.father ? (
          <Link to={`${line.father.id}`} relative='path'>
            {line.father.name}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'PatientTags',
      width: 250,
      //   ellipsis: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'PatientTags',
      }),
      editable: true,
      render: (tags) =>
        tags.map((tag) => {
          const { color, text, id } = tag;
          return (
            <Tooltip key={id} placement='topLeft' title={text}>
              <Tag color={color}>{text.toUpperCase()}</Tag>
            </Tooltip>
          );
        }),
    },
  ];
};
