import { Tooltip, Tag, Space, Avatar } from 'antd';

function SampleVarTraitsColumns(sampleId) {
  let SampleVariantTraitsColumns = [
    {
      title: 'Count',
      width: 90,
      dataIndex: 'SampleVariantTrait.count',
      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.count}>
          {line.SampleVariantTrait?.count}
        </Tooltip>
      ),
    },
    {
      title: 'Coverage',
      width: 120,
      dataIndex: 'SampleVariantTrait.coverage',
      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.coverage}>
          {line.SampleVariantTrait?.coverage}
        </Tooltip>
      ),
    },
    {
      title: 'Frequency',
      width: 120,
      dataIndex: 'SampleVariantTrait.frequency',
      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.frequency}>
          {line.SampleVariantTrait?.frequency}
        </Tooltip>
      ),
    },
    {
      title: 'Fwd Count',
      width: 90,
      dataIndex: 'SampleVariantTrait.fwdCount',
      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.fwdCount}>
          {line.SampleVariantTrait?.fwdCount}
        </Tooltip>
      ),
    },
    {
      title: 'Fwd Coverage',
      width: 120,
      dataIndex: 'SampleVariantTrait.fwdCoverage',
      render: (_, line) => (
        <Tooltip
          placement='topLeft'
          title={line.SampleVariantTrait?.fwdCoverage}
        >
          {line.SampleVariantTrait?.fwdCoverage}
        </Tooltip>
      ),
    },
    {
      title: 'Genotype',
      width: 120,
      dataIndex: 'SampleVariantTrait.genotype',
      ellipsis: true,

      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.genotype}>
          {line.SampleVariantTrait?.genotype}
        </Tooltip>
      ),
    },
    {
      title: 'Rev Count',
      width: 90,
      dataIndex: 'SampleVariantTrait.revCount',
      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.revCount}>
          {line.SampleVariantTrait?.revCount}
        </Tooltip>
      ),
    },
    {
      title: 'Quality',
      width: 90,
      dataIndex: 'SampleVariantTrait.qual',
      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.qual}>
          {line.SampleVariantTrait?.qual}
        </Tooltip>
      ),
    },
    {
      title: 'Zygosity',
      width: 88,
      dataIndex: 'SampleVariantTrait.zygosity',
      ellipsis: true,

      render: (_, line) => (
        <Tooltip placement='topLeft' title={line.SampleVariantTrait?.zygosity}>
          {line.SampleVariantTrait?.zygosity}
        </Tooltip>
      ),
    },
  ];

  SampleVariantTraitsColumns = sampleId ? SampleVariantTraitsColumns : [];

  return SampleVariantTraitsColumns;
}
export default SampleVarTraitsColumns;
