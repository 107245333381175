import { Skeleton, Result, Empty, Table, Tag } from 'antd';
import { useFetchGroupMembers } from '../../../assets/reactQuerry/groups';

const App = () => {
  const {
    isLoading,
    isError,
    error,
    data: usersData,
  } = useFetchGroupMembers({});

  if (isLoading) return <Skeleton active />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Omlouvame se, neco se porouchalo'
      />
    );

  if (!usersData?.length) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nic nenalezeno!</span>}
      />
    );
  }
  console.log(usersData);
  const uniqueGroups = [
    ...new Set(usersData.map((user) => user['Group.name'])),
  ];
  const filterGroups = uniqueGroups.map((group) => {
    return { text: group, value: group };
  });

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.firstName.length - b.firstName.length,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      // sorter: (a, b) => a.lastName - b.lastName,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <Tag color={status === 'active' ? 'green' : 'orange'}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Group',
      dataIndex: 'Group.name',
      filters: filterGroups,
      onFilter: (value, record) => record['Group.name'].indexOf(value) === 0,
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={usersData}
    />
  );
};
export default App;
