import { useState, useEffect } from 'react';
import {
  useFetchVarComments,
  useEditVarComment,
  useDeleteVarComment,
} from '../../../assets/reactQuerry/varComments';
import {
  Skeleton,
  Result,
  Empty,
  Table,
  Tag,
  Button,
  Tooltip,
  Flex,
  Space,
} from 'antd';
import { Form, Popconfirm, Typography } from 'antd';
import Highlighter from 'react-highlight-words';

import reorderParams from './assets/reorderParams';
import EditableCell from './assets/EditableCell';
import VarCommentUpload from './assets/VarCommentUpload';
import ColumnSearchProps from '../../../assets/getColumnSearchProps';

const FilterOptions = {
  VarSeverityTags: [
    { label: 'Benign', value: 1, color: 'green' },
    { label: 'VUS', value: 2, color: 'blue' },
    { label: 'Likely benign', value: 3, color: 'lime' },
    { label: 'Likely pathogenic', value: 4, color: 'orange' },
    { label: 'Pathogenic', value: 5, color: 'volcano' },
  ],
};

function VarComments() {
  const [SearchOptions, setSearchOptions] = useState('');
  const { editVarComment } = useEditVarComment();
  const [editingKey, setEditingKey] = useState('');
  const { deleteVarComment } = useDeleteVarComment();

  const [form] = Form.useForm();
  const isEditing = (record) => record.id === editingKey;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchOptions((old) => {
      const updatedOptions = { ...old, [dataIndex]: selectedKeys };
      setTableParams((prevTableParams) => ({
        //needs to be update filter here to have the latest updatedOptions
        ...prevTableParams,
        filters: { ...prevTableParams.filters, ...updatedOptions },
      }));
      return updatedOptions; // Return the updated options
    });
  };

  const handleReset = (dataIndex) => {
    if (dataIndex) {
      setSearchOptions((old) => {
        const updatedOptions = { ...old, [dataIndex]: null };
        setTableParams((prevTableParams) => ({
          //needs to be update filter here to have the latest updatedOptions
          ...prevTableParams,
          filters: { ...updatedOptions },
        }));
        return updatedOptions; // Return the updated options
      });
    } else {
      setSearchOptions('');
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        filters: {},
      }));
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    field: 'id', //for not refetching, default descend sort id
    order: 'descend',
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      ...sorter,
    }));
  };

  const params = reorderParams(tableParams, handleReset);
  const { isLoading, isError, data: varComments } = useFetchVarComments(params);

  useEffect(() => {
    if (varComments && varComments.count !== tableParams.pagination.total) {
      handleTableChange({
        total: varComments.count,
      });
    }
  }, [varComments]);

  if (isLoading) return <Skeleton />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Sorry, something went wrong...'
      />
    );

  if (!varComments) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nothing found!</span>}
      />
    );
  }

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
    form.resetFields();
  };
  const save = async (id) => {
    const data = await form.validateFields();
    console.log(data);
    editVarComment({ id, newData: data });
    setEditingKey('');
    form.resetFields();
  };

  const ColumnsSearchPropsDefault = {
    handleSearch,
    handleReset,
    FilterOptions,
    SearchOptions,
  };

  const columns = [
    {
      title: 'Chr',
      dataIndex: 'chr',
      //   render: (chr, line) => <span>{line.Variant?.Loc?.chr}</span>,
      render: (_, line) =>
        SearchOptions?.chr ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.chr[0]]}
            autoEscape
            textToHighlight={line.Variant?.Loc?.chr.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.Variant?.Loc?.chr}>
            {line.Variant?.Loc?.chr}
          </Tooltip>
        ),
      width: 80,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'chr',
      }),
    },
    {
      title: 'Pos',
      dataIndex: 'pos',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (_, line) =>
        SearchOptions?.pos ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.pos[0]]}
            autoEscape
            textToHighlight={line.Variant?.Loc?.pos.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.Variant?.Loc?.pos}>
            {line.Variant?.Loc?.pos}
          </Tooltip>
        ),
      //   ...getColumnSearchProps('pos'),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'pos',
      }),
    },
    {
      title: 'Ref',
      dataIndex: 'ref',
      width: 100,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions?.ref ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.ref[0]]}
            autoEscape
            textToHighlight={line.Variant?.ref.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.Variant?.ref}>
            {line.Variant?.ref}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'ref',
      }),
    },
    {
      title: 'Alt',
      dataIndex: 'alt',
      render: (_, line) => <span>{line.Variant?.alt}</span>,
      width: 100,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions?.alt ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.alt[0]]}
            autoEscape
            textToHighlight={line.Variant?.alt.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.Variant?.alt}>
            {line.Variant?.alt}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'alt',
      }),
    },

    {
      title: 'Severity',
      dataIndex: 'VarSeverityTags',
      editable: true,
      width: 140,
      render: (_, line) =>
        line.VarSeverityTags?.map((tag) => {
          const { color, text, id } = tag;
          return (
            <Tooltip key={id} placement='topLeft' title={text}>
              <Tag color={color}>{text.toUpperCase()}</Tag>
            </Tooltip>
          );
        }),

      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarSeverityTags',
      }),
    },
    {
      title: 'cName',
      dataIndex: 'cName',
      editable: true,

      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement='top' title={text}>
          {text}
        </Tooltip>
      ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'cName',
      }),
    },
    {
      title: 'pName',
      dataIndex: 'pName',
      editable: true,
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement='top' title={text}>
          {text}
        </Tooltip>
      ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'pName',
      }),
    },
    {
      title: 'Transcript',
      dataIndex: 'transcript',
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'transcript',
      }),
    },
    {
      title: 'Gene',
      dataIndex: 'gene',
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'gene',
      }),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      editable: true,
      ellipsis: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'comment',
      }),
    },
    {
      title: 'Links',
      dataIndex: 'links',
      editable: true,
      ellipsis: true,
      render: (links) =>
        links && (
          <Space wrap>
            {links.map((link) => (
              <a key={link} href={link}>
                {link}
              </a>
            ))}
          </Space>
        ),
    },

    {
      title: 'operation',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right',

      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Flex wrap='wrap' gap='small'>
            {editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(record.id)}
                  style={{ display: 'block' }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <>
                <Typography.Link
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Flex gap='middle' justify='center' align='center'>
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <VarCommentUpload />
          <Button onClick={() => handleReset()}>Clear filters</Button>
        </Space>
      </Flex>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          rowKey={(record) => record.id}
          bordered
          dataSource={varComments?.rows}
          onChange={handleTableChange}
          scroll={{
            x: 1500,
          }}
          pagination={tableParams.pagination}
        />
      </Form>
    </>
  );
}
export default VarComments;
