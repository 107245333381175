import { useEffect } from 'react';
import GroupUsers from './Colleagues';
import MyInfo from './MyInfo';
import MyLogs from './MyLogs';
import { Divider, App } from 'antd';
import { Anchor, Row, Col } from 'antd';
import io from 'socket.io-client';
const socketServer = process.env.REACT_APP_SERVER_SOCKET;

function PatientDetail() {
  const { notification } = App.useApp();

  //   useEffect(() => {
  //     console.log(socketServer)
  //     const socket = io(socketServer, { transports: ['websocket'] })
  //     console.log('WebSocket connection status:', socket.connected)

  //     socket.on('message', (msg) => {
  //       console.log('Received message from server:', JSON.stringify(msg))
  //       notification.info({
  //         message: `Notification topLeft`,
  //         description: JSON.stringify(msg),
  //         placement: 'topLeft',
  //       })
  //       // Handle the received message as needed
  //     })
  //     return () => {
  //       socket.disconnect()
  //     }
  //   }, [])

  return (
    <Row>
      <Col span={20}>
        <div id='myinfo'>
          <Divider orientation='left'>My info</Divider>
          <MyInfo />
        </div>

        <Divider orientation='left'>My Logs</Divider>
        <div id='logs'>
          <MyLogs />
        </div>

        <Divider orientation='left'>Group members</Divider>
        <div id='Users'>
          <GroupUsers />
        </div>
      </Col>

      <Col span={4}>
        <Anchor
          items={[
            {
              key: 'part-1',
              href: '#myinfo',
              title: 'My Info',
            },
            {
              key: 'part-2',
              href: '#logs',
              title: 'My Logs',
            },
            {
              key: 'part-3',
              href: '#Users',
              title: 'Team Users',
            },
          ]}
        />
      </Col>
    </Row>
  );
}
export default PatientDetail;
