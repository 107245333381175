import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const LIMIT = 20;
const OFFSET = 0;
const SORT = '';
const FILTERS = '';

export const useFetchBeds = ({
  offset = OFFSET,
  limit = LIMIT,
  filters = FILTERS,
  sort = SORT,
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['beds', limit, offset, sort, filters],
    queryFn: async () => {
      console.log(
        `/beds/?limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      const { data } = await authFetch.get(
        `/beds/?limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      return data;
    },
  });
  return { isLoading, isError, error, data };
};

export const useEditBed = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: editBed } = useMutation({
    mutationFn: ({ bedId, newData }) => {
      return authFetch.patch(`/beds/${bedId}`, newData);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['beds'],
      });
      message.success('Bed was successfully edited');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { editBed };
};

export const useDeleteBed = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: deleteBed } = useMutation({
    mutationFn: (id) => {
      return authFetch.delete(`/beds/${id}`);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['beds'],
      });
      message.success('Bed was successfully removed');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { deleteBed };
};

export const useAddBed = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: addBed, isPending } = useMutation({
    mutationFn: ({ newData }) => {
      return authFetch.post(`/beds/`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['beds'],
      });
      message.success('New bed was created');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { addBed, isPending };
};
