import axios from 'axios';
const server =
  process.env.NODE_ENV == 'production'
    ? 'https://avigena-se.dyn.cloud.e-infra.cz'
    : process.env.REACT_APP_SERVER_URL;

// Create a new instance of Axios with the base URL
const authAxios = axios.create({
  baseURL: `${server}/api/v1/`, // Replace with your API URL
});

// Add a request interceptor to attach the bearer token to the request headers
authAxios.interceptors.request.use((config) => {
  const token =
    sessionStorage.getItem('bearerToken') ||
    localStorage.getItem('bearerToken') ||
    null; // Get the token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Export the authFetch function
export default authAxios;
