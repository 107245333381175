import { Button, Result } from 'antd'
import { useRouteError, Link } from 'react-router-dom'

const App = () => (
  <Result
    status="500"
    title="500"
    subTitle="Sorry, something went wrong."
    extra={
      <Button type="primary">
        <Link to=".." relative="path">
          Zpatky
        </Link>
        {/* <Link to={`/`}>Domu</Link> */}
      </Button>
    }
  />
)
export default App
