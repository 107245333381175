import { Breadcrumb, Layout, Menu, Avatar } from 'antd';
import {
  LoginOutlined,
  IdcardOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import './index.css';
import { useSessionContext } from '../../assets/SessionContextProvider';
import { stringToColour } from '../../assets/ColorFunc';
import { Outlet, NavLink, Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { SampleIcon, VariantIcon } from './assets';

import WebSockets from '../../assets/WebSocket';
// import Breadcrumbs from '../../assets/Breadcrumbs'
const { Header, Content, Footer } = Layout;
// const { useToken } = theme

const items = [
  {
    label: (
      <NavLink to={`/app`}>
        <Avatar
          size={60}
          style={{
            backgroundColor: 'white',
            color: 'black',
            marginRight: '50px',
          }}
        >
          Logo
        </Avatar>
      </NavLink>
    ),
    disabled: true,
  },

  {
    label: <NavLink to={`/app/patients`}>Probands</NavLink>,
    key: 'patients',
    // icon: <IdcardOutlined />,
  },
  {
    label: <NavLink to={`/app/samples`}>Samples</NavLink>,
    key: 'samples',
    // icon: <SampleIcon />,
  },
  {
    label: <NavLink to={`/app/variants`}>Variants</NavLink>,
    key: 'variants',
    // icon: <VariantIcon />,
  },

  {
    label: <NavLink to={`/app/beds`}>Beds</NavLink>,
    key: 'beds',
    // icon: <SettingOutlined />,
  },
];
const BreadDict = { app: 'Home', patients: 'patients' };

const WrapperPage = ({ children }) => {
  const { userinfo, token, setToken, setUserinfo } = useSessionContext();
  const [menuitems, setMenuitems] = useState(items);
  const location = useLocation();
  const navigate = useNavigate();
  let splited = location.pathname.split('/');
  splited = splited.filter((item) => item !== ''); // Filter out empty items
  const crumbs = splited.map((crumb, index) => {
    const path = '/' + splited.slice(0, index + 1).join('/');
    const name = BreadDict[crumb] || crumb;
    return { title: <Link to={path}>{name}</Link> };
  });

  //   const {
  //     token: { colorBgContainer },
  //   } = theme.useToken();

  useEffect(() => {
    const resetMenuItems = () => {
      const rightMenuItem = token
        ? {
            key: 'UserSetting',
            style: { marginLeft: 'auto' },
            label: (
              <Avatar
                size={50}
                style={{
                  backgroundColor: stringToColour(userinfo.name),
                  color: 'black',
                }}
              >
                {userinfo.name && userinfo.name.toUpperCase()}
              </Avatar>
            ),
            children: [
              {
                label: <NavLink to={`/app/user`}>Profile</NavLink>,
                key: 'profile',
                icon: <SettingOutlined />,
              },
              userinfo.role !== 'USER' //EDIT HERE
                ? {
                    label: (
                      <NavLink to={`/app/user/group`}>
                        Group leader settings
                      </NavLink>
                    ),
                    key: 'leader',
                    icon: <SettingOutlined />,
                  }
                : null,
              {
                label: 'Log out',
                icon: <LogoutOutlined />,
                danger: true,
                key: 'logout',
                onClick: (e) => {
                  sessionStorage.removeItem('bearerToken');
                  localStorage.removeItem('bearerToken');
                  sessionStorage.removeItem('userInfo');
                  localStorage.removeItem('userInfo');
                  setToken(null);
                  setUserinfo(null);
                  navigate('/');
                },
              },
            ],
          }
        : {
            style: { marginLeft: 'auto' },
            label: <NavLink to={`/login`}>Log in</NavLink>,
            key: 'login',
            icon: <LoginOutlined />,
          };
      return rightMenuItem;
    };
    const rightMenuItem = resetMenuItems();
    setMenuitems((items) => {
      console.log(items);
      const alreadyExists = items.some(
        (item) => item.key === rightMenuItem.key
      );
      if (!alreadyExists) {
        return [...items, rightMenuItem];
      }
      return items;
    });
  }, [token, userinfo]);
  console.log(menuitems);

  return (
    <Layout>
      <WebSockets />
      <Header>
        <Menu
          theme='dark'
          mode='horizontal'
          defaultSelectedKeys={[splited[1]]}
          style={{ display: 'flex' }}
          items={menuitems}
        />
      </Header>
      {crumbs.length > 1 && (
        <Breadcrumb className='breadcrumb' items={crumbs} />
      )}
      <Content>
        <div
          className='site-layout-content'
          style={{
            // background: colorBgContainer,
            minHeight: '100vh',
          }}
        >
          {location.pathname === '/app' || location.pathname === '/' ? (
            children
          ) : (
            <Outlet />
          )}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Germini ©2024 v1.0.1a
      </Footer>
    </Layout>
  );
};

export default WrapperPage;
