import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { create } from 'pinch-zoom-pan'
import './index.css'

function PinchZoomPan({ min, max, captureWheel, className, style, children }) {
  const root = useRef(null)

  useEffect(() => {
    const element = root.current
    if (!element) return
    const canvas = create({ element, minZoom: min, maxZoom: max, captureWheel })
    return canvas.destroy
  }, [min, max, captureWheel])

  return (
    <div
      ref={root}
      className={classNames(className, 'FamilyTree')}
      style={style}
    >
      <div className="point">
        <div className="canvas">{children}</div>
      </div>
    </div>
  )
}

export default React.memo(PinchZoomPan)
