import { Divider } from 'antd';
import Description from './description';
import FamilyTree from './tree';
import SampleTimeline from './timeline';
import { Anchor, Row, Col } from 'antd';
import './index.css';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';

function PatientDetail() {
  return (
    <Row>
      <Col span={21}>
        <div id='Desc'>
          <Divider orientation='left'>Subject info</Divider>
          <Description />
        </div>

        <Divider orientation='left'>Family tree</Divider>
        <div id='Familytree'>
          <ErrorBoundary>
            <FamilyTree />
          </ErrorBoundary>
        </div>

        <Divider orientation='left'>Samples</Divider>
        <div id='Samples'>
          <SampleTimeline />
        </div>
      </Col>

      <Col span={3}>
        <Anchor
          items={[
            {
              key: 'part-1',
              href: '#Desc',
              title: 'Subject info',
            },
            {
              key: 'part-2',
              href: '#Familytree',
              title: 'Family tree',
            },
            {
              key: 'part-3',
              href: '#Samples',
              title: 'Samples',
            },
          ]}
        />
      </Col>
    </Row>
  );
}
export default PatientDetail;
