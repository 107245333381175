import axios from 'axios';

const server =
  process.env.NODE_ENV == 'production'
    ? 'https://avigena-se.dyn.cloud.e-infra.cz'
    : process.env.REACT_APP_SERVER_URL;

console.log(server);

// Create a new instance of Axios with the base URL
const pubFetch = axios.create({
  baseURL: `${server}/api/v1/`, // Replace with your API URL
});

export default pubFetch;
