import {
  Skeleton,
  Result,
  Empty,
  Table,
  Tag,
  Flex,
  Popconfirm,
  Typography,
} from 'antd';
import { useFetchGroupMembers } from '../../../assets/reactQuerry/groups';

import {
  useDeleteUser,
  useApproveUser,
} from '../../../assets/reactQuerry/users';

const statusColorDictionary = {
  active: 'green',
  unapproved: 'orange',
  unverified: 'red',
};

const Members = () => {
  const {
    isLoading,
    isError,
    error,
    data: usersData,
  } = useFetchGroupMembers({ groupId: 'my' });
  const { deleteUser, deleteUserLoading } = useDeleteUser();
  const { approveUser } = useApproveUser();
  if (isLoading) return <Skeleton active />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Omlouvame se, neco se porouchalo'
      />
    );

  if (!usersData?.length) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nic nenalezeno!</span>}
      />
    );
  }

  const uniqueGroups = [
    ...new Set(usersData.map((user) => user['Group.name'])),
  ];
  const filterGroups = uniqueGroups.map((group) => {
    return { text: group, value: group };
  });

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <Tag color={statusColorDictionary[status]}>{status.toUpperCase()}</Tag>
      ),
    },
    {
      title: 'Group',
      dataIndex: 'Group.name',
      filters: filterGroups,
      onFilter: (value, record) => record['Group.name'].indexOf(value) === 0,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        // const editable = isEditing(record)
        return (
          <Flex wrap='wrap' gap='small'>
            <Typography.Link
              //   disabled={editingKey !== ''}
              onClick={() => approveUser(record.id)}
            >
              Approve
            </Typography.Link>

            <Popconfirm
              danger
              title='Do you really want to delete this user?'
              onConfirm={() => {
                deleteUser(record.id);
              }}
            >
              <Typography.Link type='danger'>Delete</Typography.Link>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={usersData}
    />
  );
};
export default Members;
