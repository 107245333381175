import EditPatient from './EditPatient';

function EditPatientPage() {
  return (
    <>
      {' '}
      <EditPatient />
    </>
  );
}
export default EditPatientPage;
