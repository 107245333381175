import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';
const ID = 'all';

export const useFetchUsers = (id = ID) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['users', id],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;
      const { data } = await authFetch.get(`/users/get/${id}`);
      return data;
    },
  });

  return { isLoading, isError, error, data };
};

export const useFetchMe = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['users', 'me'],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;
      const { data } = await authFetch.get(`/users/me`);
      return data;
    },
  });

  return { isLoading, isError, error, data };
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: deleteUser, isLoading: deleteUserLoading } = useMutation({
    mutationFn: (userId) => {
      return authFetch.delete(`users/${userId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users', ID] });
      message.success('User was deleted!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error('Error occured while deleting user! :' + error);
    },
  });
  return { deleteUser, deleteUserLoading };
};

export const useApproveUser = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: approveUser } = useMutation({
    mutationFn: (userId) => {
      return authFetch.patch(`users/approve/${userId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users', ID] });
      message.success('User was approved!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error(error);
    },
  });
  return { approveUser };
};
