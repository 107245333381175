import { Divider, Anchor, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';

import SampleDescription from './SampleDescription.js';
import SampleVariants from '../../variants/index.js';

function DetailsPage() {
  let { id } = useParams();

  return (
    <>
      <Row>
        <Col span={21}>
          <div id='desc'>
            <Divider orientation='left'>Sample description</Divider>
            <SampleDescription id={id} />
          </div>

          <div id='variants'>
            <Divider orientation='left'>Sample variants</Divider>
            <SampleVariants sampleId={id} />
          </div>
        </Col>

        <Col span={3}>
          <Anchor
            items={[
              {
                key: 'part-1',
                href: '#desc',
                title: 'Description',
              },
              {
                key: 'part-2',
                href: '#variants',
                title: 'Variants',
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
export default DetailsPage;
