import { Form, Input, Select, Tag, Space } from 'antd';

const severityFilter = [
  { label: 'Benign', value: 'Benign', color: 'green' },
  { label: 'VUS', value: 'VUS', color: 'blue' },
  { label: 'Likely benign', value: 'Likely benign', color: 'lime' },
  { label: 'Likely pathogenic', value: 'Likely pathogenic', color: 'orange' },
  { label: 'Pathogenic', value: 'Pathogenic', color: 'volcano' },
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const severityTagsForFilter = severityFilter.map(
    ({ label, value, color }) => ({
      value,
      label,
      color,
      // label: { text, color },
    })
  );

  const tagRender = (props, labels) => {
    const thisLabel = labels.find((label) => label.value === props.value);
    const { label, value, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (!value) return null;
    return (
      <Tag
        color={thisLabel.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const SelectSeverityTags = (
    <Select dropdownStyle={{ width: 'auto' }} options={severityTagsForFilter} />
  );

  let inputNode;
  let rule;

  switch (dataIndex) {
    case 'severity':
      dataIndex = 'severity';
      inputNode = SelectSeverityTags;
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: '-10px -5px -10px -5px',
            padding: 0,
          }}
          rules={rule}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
