import React, { useState } from 'react';
import { Flex, Button, Modal, message, Upload, Form, Input, Space } from 'antd';

import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useAddBed } from '../../../assets/reactQuerry/beds';
const { Dragger } = Upload;
const { TextArea } = Input;

const formData = new FormData();

const BedUpload = () => {
  const { addBed, isPending } = useAddBed();
  const [fileList, setFileList] = useState([]);

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    setFileList([]);

    setOpen(false);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setFileList([]);

    setOpen(false);
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,

    beforeUpload(file) {
      console.log(file.type);
      const isBed = file.name.endsWith('.bed');
      if (!isBed) {
        message.error(`${file.name} is not a .bed file!`);
      }
      return isBed || Upload.LIST_IGNORE;
    },
    customRequest(options) {
      const { onSuccess, onError, file, onProgress } = options;
      try {
        formData.set('bed', file);
        setFileList([file]);
        onSuccess(file);
      } catch (err) {
        console.log(err);
        const error = new Error(err.response.statusText);
        message.error(err.response.data);
        onError(error);
      }
    },
  };

  return (
    <>
      <Button type='primary' onClick={showModal}>
        <UploadOutlined /> Upload new bed
      </Button>
      <Modal
        title='Create new bed'
        open={open}
        onCancel={handleCancel}
        okButtonProps={{
          style: { display: 'none' },
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
        width={1080}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 12,
          }}
          layout='horizontal'
          disabled={isPending}
          onFinish={async (values) => {
            const filteredEntries = Object.entries(values).filter(
              ([key, value]) => value
            );
            for (const [key, value] of filteredEntries) {
              formData.set(key, value);
            }
            await addBed({ newData: formData });

            handleOk();
          }}
        >
          <Form.Item name='name' label='Name'>
            <Input />
          </Form.Item>

          <Form.Item name='description' label='Description'>
            <TextArea rows={4} />
          </Form.Item>
          <Dragger {...props} style={{ marginBottom: '1rem' }}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload
            </p>
            <p className='ant-upload-hint'>
              The file to be uploaded has to be <b>.bed</b>
            </p>
          </Dragger>

          <Flex justify='flex-end'>
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type='primary' htmlType='submit'>
                OK
              </Button>
            </Space>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};
export default BedUpload;
