import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import {
  Form,
  Table,
  Popconfirm,
  Typography,
  Space,
  Flex,
  Button,
  Skeleton,
  App,
} from 'antd';
import EditableCell from './EditableCell';
import getSamplesColumns from './samplesColumns';
import { Link } from 'react-router-dom';
import { useFetchPatientIds } from '../../assets/reactQuerry/patients';
import {
  usePrefetchSamples,
  useFetchSamples,
  useEditSample,
  useSetTagsToSample,
  useDeleteSample,
} from '../../assets/reactQuerry/samples';
import { useFetchSampleTags } from '../../assets/reactQuerry/sampleTags';

import reorderParams from '../../assets/reorderParams';

import { NotExists } from '../errors';

export const loader = (queryClient) => async () => {
  const data = await queryClient.ensureQueryData(usePrefetchSamples({}));
  return data.count;
};

const SamplesTable = () => {
  const count = useLoaderData();
  const navigate = useNavigate();

  const { isLoadingIds, patientsIdName } = useFetchPatientIds('all');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  //   const [searchText, setSearchText] = useState('');
  const [SearchOptions, setSearchOptions] = useState('');

  const { isLoading: isLoadingTags, data: sampleTags } = useFetchSampleTags({});
  const { editSample } = useEditSample();
  const { message } = App.useApp();
  const { deleteSample } = useDeleteSample();
  const { setTagsToSample } = useSetTagsToSample();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchOptions((old) => {
      const updatedOptions = { ...old, [dataIndex]: selectedKeys };
      setTableParams((prevTableParams) => ({
        //needs to be update filter here to have the latest updatedOptions
        ...prevTableParams,
        filters: { ...prevTableParams.filters, ...updatedOptions },
      }));
      return updatedOptions; // Return the updated options
    });
  };

  const handleReset = (dataIndex) => {
    if (dataIndex) {
      setSearchOptions((old) => {
        const updatedOptions = { ...old, [dataIndex]: null };
        console.log(updatedOptions);
        setTableParams((prevTableParams) => ({
          //needs to be update filter here to have the latest updatedOptions
          ...prevTableParams,
          filters: { ...updatedOptions },
        }));
        return updatedOptions; // Return the updated options
      });
    } else {
      setSearchOptions('');
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        filters: {},
      }));
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    field: 'id', //for not refetching, default descend sort id
    order: 'DESC',
  });

  const params = reorderParams(tableParams, handleReset);

  //   const SampleColumns = getSamplesColumns(setTableParams);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      ...sorter,
    }));
  };

  const {
    isLoading,
    isError,
    error,
    data: samplesData,
  } = useFetchSamples(params);

  useEffect(() => {
    if (samplesData && samplesData.count !== tableParams.pagination.total) {
      handleTableChange({
        total: samplesData.count,
      });
    }
  }, [samplesData]); // Only run this effect when `data` changes

  if (isError) {
    message.error('Unable to load data');
    console.log(error);
    return (
      <NotExists subtitle={error?.response?.data ?? 'Unable to load data'} />
    );
  }

  if (isLoading || isLoadingIds || isLoadingTags) {
    return <Skeleton />;
  }

  const SampleColumns = getSamplesColumns({
    SearchOptions,
    handleSearch,
    handleReset,
    sampleTags,
    patientsIdName,
  });

  //   const filteredData = !isLoading ? filterTable(data, searchText) : null;

  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      originDate: record.originDate ? dayjs(record.originDate) : null,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const { SampleTags, ...SampleInfo } = row;
      console.log(row);
      SampleTags.length &&
        setTagsToSample({
          sampleId: key,
          SampleTagIds: SampleTags,

          //   SampleTagIds: SampleTags.map((tag) => tag.id),
        });
      editSample({ sampleId: key, newData: SampleInfo });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error(`Data not uploaded ${errInfo}`); // Display an error message
    }
  };

  const colums_editCol = [
    ...SampleColumns,
    {
      dataIndex: 'operation',
      width: 160,
      fixed: 'right',

      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                display: 'block',
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <Typography.Link type='danger'>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Flex wrap='wrap' gap='small'>
            <Link to={`${record.id}`} relative='path'>
              Detail
            </Link>
            <Typography.Link
              type='warning'
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>

            <Popconfirm
              danger
              title='Sure to delete?'
              onConfirm={() => {
                deleteSample(record.id);
              }}
            >
              <Typography.Link type='danger'>Delete</Typography.Link>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = colums_editCol.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Flex gap='middle' justify='center' align='center'>
        <Space style={{ padding: '1rem' }}>
          <Space.Compact>
            <Button type='primary' onClick={() => navigate('/app/samples/new')}>
              New sample
            </Button>
          </Space.Compact>
          <Button onClick={() => handleReset()}>Clear filters</Button>
        </Space>
      </Flex>

      <Form form={form} component={false}>
        <Table
          loading={isLoading}
          rowKey={(record) => record.id}
          components={{
            body: {
              cell: (cell) => (
                <EditableCell {...cell} data={{ patientsIdName, sampleTags }} />
              ),
            },
          }}
          bordered
          scroll={{
            x: '900',
          }}
          dataSource={samplesData.data}
          columns={mergedColumns}
          rowClassName='editable-row'
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </Form>
    </div>
  );
};
export default SamplesTable;
