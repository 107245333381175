import { useEffect, useState } from 'react';
import {
  Descriptions,
  Empty,
  Tag,
  Image,
  Button,
  Flex,
  Skeleton,
  message,
  Result,
} from 'antd';
import './index.css';
import { useFetchSamples } from '../../../assets/reactQuerry/samples';
import { Link } from 'react-router-dom';
import authFetch from '../../../assets/axios/authFetch';

const SampleDescription = ({ id }) => {
  const {
    isLoading,
    isError,
    error,
    data: sampleData,
  } = useFetchSamples({ id, full: false });
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError && sampleData.photo_paths) {
      const imgNames = sampleData.photo_paths;
      const ids = Array(imgNames.length).fill(id);

      const fetchData = async () => {
        for (let i = 0; i < imgNames.length; i++) {
          try {
            const response = await authFetch.get(
              `/patients/${ids[i]}/image/${imgNames[i]}`,
              {
                responseType: 'arraybuffer',
              }
            );
            const base64Data = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            setPhotos((oldPhotos) => [
              ...oldPhotos,
              `data:image/png;base64,${base64Data}`,
            ]);
          } catch (error) {
            console.error(error);
            message.error(error.message);
          }
        }
      };

      fetchData(); // Invoke the fetchData function
    }
  }, [isLoading, sampleData]);

  if (isLoading) return <Skeleton active />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Sorry, something went wrong..'
      />
    );

  if (!sampleData.id) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nothing found!</span>}
      />
    );
  }

  const items = [
    {
      key: '1',
      label: 'Name',
      children: `${sampleData.name}`,
    },
    {
      key: '2',
      label: 'Patient',
      children: sampleData.PatientId ? (
        <Link to={`../${sampleData.Patient.id}`} relative='path'>
          {sampleData.Patient.name}
        </Link>
      ) : (
        '-'
      ),
    },

    {
      key: '3',
      label: 'Comment',
      children: sampleData.comment,
    },

    {
      key: '4',
      label: 'Sample tags',
      children: sampleData.SampleTags.map((tag) => {
        const { color, label, id } = tag;
        return (
          <Tag color={color} key={id}>
            {label.toUpperCase()}
          </Tag>
        );
      }),
    },
    {
      key: '7',
      label: 'Photos',
      children: (
        <Flex wrap='wrap' gap='small'>
          <Image.PreviewGroup preview='false'>
            {photos.map((data, index) => {
              return <Image key={index} width={100} src={data} />;
            })}
          </Image.PreviewGroup>
        </Flex>
      ),
    },
  ];
  return (
    <div className='table'>
      <Descriptions
        // extra={
        //   <Button type="primary" style={{ left: '0' }}>
        //     <Link to="edit" relative="path">
        //       Editovat
        //     </Link>
        //   </Button>
        // }
        title={`${sampleData.name}`}
        layout='vertical'
        bordered={true}
        column={{ sm: 1, md: 2, lg: 4, xxl: 6 }}
        items={items}
      />
      <div className='centerButton'>
        <Button type='primary'>
          <Link to='edit' relative='path'>
            Edit
          </Link>
        </Button>
      </div>
    </div>
  );
};
export default SampleDescription;
