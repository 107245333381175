const reorderParams = (params) => {
  const { field, order, pagination, filters } = params;

  const sort =
    field && order
      ? [field, order.toLowerCase() === 'ascend' ? 'ASC' : 'DESC'].join(':')
      : undefined;

  return {
    sort,
    offset: (params.pagination?.current - 1) * params.pagination?.pageSize,
    limit: pagination.pageSize,
    filters:
      filters &&
      Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      ),
  };
};

export default reorderParams;
