import { useEffect, useMemo, useRef, useState } from 'react';
import { Select, Spin, Tag } from 'antd';
import debounce from 'lodash/debounce';

import authFetch from './axios/authFetch';

const tagRender = (props, options) => {
  const { label, value, closable, onClose } = props;
  //   console.log(props);
  const thisItem = options?.find((item) => item.value === props.value);

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  if (!value) return null;
  return (
    <Tag
      color={thisItem?.color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

// Usage of DebounceSelect

async function fetchData(searchText) {
  return authFetch.get(`/patienttag/?text=${searchText}`).then((response) =>
    response.data.map((tag) => ({
      label: tag.text,
      value: tag.id,
      color: tag.color,
    }))
  );
}

const Selectoptions = [];
for (let i = 10; i < 36; i++) {
  Selectoptions.push({
    label: i,
    value: i,
  });
}

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  defaulttags,
  ...props
}) {
  const parsedTags =
    defaulttags &&
    defaulttags.map((tag) => ({
      value: tag.id,
      label: tag.text,
      color: tag.color,
    }));
  const tagIds = defaulttags && defaulttags.map((tag) => tag.id);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(parsedTags);
  const [value, setValue] = useState(tagIds);

  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchData(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchData, debounceTimeout]);
  console.log(value);
  return (
    <Select
      onChange={(newValue) => setValue(newValue)}
      tagRender={(props) => tagRender(props, options)}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      defaultValue={value}
      style={{
        width: '100%',
      }}
      mode='multiple'
      placeholder='Select tags'
      options={options}
      props
    />
  );
}

export default DebounceSelect;
