import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const LIMIT = 20;
const OFFSET = 0;
const SORT = '';
const FILTERS = '';

export const useFetchVarComments = ({
  offset = OFFSET,
  limit = LIMIT,
  filters = FILTERS,
  sort = SORT,
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['varComments', limit, offset, sort, filters],
    queryFn: async () => {
      const { data } = await authFetch.get(
        `/varcomments/?limit=${limit}&offset=${offset}&order=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );

      console.log(
        `/varcomments/?limit=${limit}&offset=${offset}&order=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );

      return data;
    },
  });
  return { isLoading, isError, error, data };
};

export const useEditVarComment = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: editVarComment } = useMutation({
    mutationFn: ({ id, newData }) => {
      console.log(newData);
      console.log(`/varcomments/${id}`);
      return authFetch.patch(`/varcomments/${id}`, newData);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['varComments'], ['samples'], ['variants']);
      message.success('Variant note successfully edited');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { editVarComment };
};

export const useDeleteVarComment = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: deleteVarComment } = useMutation({
    mutationFn: (id) => {
      return authFetch.delete(`/varcomments/${id}`);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['varComments'], ['variants']);
      message.success('Variant note was successfully removed');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { deleteVarComment };
};

export const useUploadBatchVarComment = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const {
    mutateAsync: uploadBatchVarComment,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: ({ table }) => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      return authFetch.post(`/varcomments/upload`, table, config);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['varComments'],
      });
      message.success('New variant comments were uploaded');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { uploadBatchVarComment, isLoading, isPending };
};
