import Login from '../pages/login';
import Register from '../pages/register/register';
import { createBrowserRouter } from 'react-router-dom';
import Patients from '../pages/patients';
import PatientsDetail from '../pages/patients/detail/';
import PatientEdit from '../pages/patients/edit';

import Samples from '../pages/samples';
import NewSample from '../pages/samples/new';
import SamplesDetail from '../pages/samples/detail';

import Beds from '../pages/beds';

import Variants from '../pages/variants';

import PageWrapper from '../pages/wrapper';
import { ProtectedSubRoutes } from './ProtectedRoutes';
import { NotExists, InternalError } from '../pages/errors/';
import Table from '../pages/table/Table';
import AppWelcome from '../pages/welcome';
import PersonalSettings from '../pages/user/me';
import LeaderSettings from '../pages/user/leader';
import { useQueryClient } from '@tanstack/react-query';
import { loader as patientLoader } from '../pages/patients/patientsTable';

const Routes = () => {
  const queryClient = useQueryClient();

  return createBrowserRouter([
    {
      path: '/',
      element: (
        <PageWrapper>
          <h1>General welcome</h1>
        </PageWrapper>
      ),
      errorElement: <NotExists />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: <Register />,
        },

        {
          path: '404',
          element: <NotExists />,
        },
      ],
    },
    {
      path: '/app',
      element: (
        <ProtectedSubRoutes>
          <PageWrapper>
            <h1>App welcome</h1>
            <AppWelcome />
          </PageWrapper>
        </ProtectedSubRoutes>
      ),
      // errorElement: <InternalError />,
      children: [
        {
          path: 'patients',
          children: [
            {
              index: true,
              element: <Patients />,
              loader: patientLoader(queryClient),
              errorElement: <InternalError />,
            },
            {
              path: ':id',
              element: <PatientsDetail />,
            },
            { path: ':id/edit', element: <PatientEdit /> },
          ],
        },
        {
          path: 'samples',
          children: [
            {
              index: true,
              element: <Samples />,
              //   loader: patientLoader(queryClient),
            },
            {
              path: ':id',
              element: <SamplesDetail />,
            },
            {
              path: 'new',
              element: <NewSample />,
            },
            // { path: ':id/edit', element: <PatientEdit /> },
          ],
        },
        {
          path: 'user',
          children: [
            { index: true, element: <PersonalSettings /> },
            {
              path: 'group',

              element: <LeaderSettings />,
            },
          ],
        },
        {
          path: 'beds',
          children: [
            {
              index: true,
              element: <Beds />,
            },
          ],
        },
        {
          path: 'variants',
          children: [
            {
              index: true,
              element: <Variants />,
            },
          ],
        },
        { path: 'table', children: [{ index: true, element: <Table /> }] },
      ],
    },
  ]);
};

// const Routes = createBrowserRouter([
//   {
//     path: '/',
//     element: (
//       <PageWrapper>
//         <h1>General welcome</h1>
//       </PageWrapper>
//     ),
//     errorElement: <NotExists />,
//     children: [
//       {
//         path: 'login',
//         element: <Login />,
//       },
//       {
//         path: 'register',
//         element: <Register />,
//       },

//       {
//         path: '404',
//         element: <NotExists />,
//       },
//     ],
//   },
//   {
//     path: '/app',
//     element: (
//       <ProtectedSubRoutes>
//         <PageWrapper>
//           <h1>App welcome</h1>
//           <AppWelcome />
//         </PageWrapper>
//       </ProtectedSubRoutes>
//     ),
//     // errorElement: <InternalError />,
//     children: [
//       {
//         path: 'patients',
//         children: [
//           {
//             index: true,
//             element: <Patients />,
//             // loader: patientLoader(),
//           },
//           {
//             path: ':id',
//             element: <PatientsDetail />,
//           },
//           { path: ':id/edit', element: <PatientEdit /> },
//         ],
//       },
//       {
//         path: 'user',
//         children: [
//           { index: true, element: <PersonalSettings /> },
//           {
//             path: 'group',

//             element: <LeaderSettings />,
//           },
//         ],
//       },
//       { path: 'table', children: [{ index: true, element: <Table /> }] },
//     ],
//   },
// ]);

export default Routes;
