import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const ID = 'all';
const LIMIT = 10;
const OFFSET = 0;
const SORT = '';
const FILTERS = {};

export const useFetchVariants = ({
  id = '',
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
  isEnabled = true,
  sampleId = '',
  patientId = '',
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['variants', id, sampleId, limit, offset, sort, filters],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;
      console.log(
        `/variants/?variantId=${id}&sampleId=${sampleId}&patientId=${patientId}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      const { data } = await authFetch.get(
        `/variants/?variantId=${id}&sampleId=${sampleId}&patientId=${patientId}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );

      return data;
    },
    enabled: isEnabled,
  });
  return { isLoading, isError, error, data };
};

export const useFetchVariantCoverage = ({
  id = '',
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
  isEnabled = true,
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['variantsCov', id, limit, offset, sort, filters],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;
      const { data } = await authFetch.get(
        `/variants/${id}/coverage/?limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );

      return data.data;
    },
    enabled: isEnabled,
  });
  return { isLoading, isError, error, data };
};

export const useFetchTranscripts = ({ variantId }) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['transcripts', variantId],
    queryFn: async ({ queryKey }) => {
      const [_, variantId] = queryKey;
      const { data } = await authFetch.get(`/variants/${variantId}/`);

      return data.data;
    },
  });
  return { isLoading, isError, error, data };
};

export const useEditVariant = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: editVariant } = useMutation({
    mutationFn: ({ sampleId, newData }) => {
      return authFetch.patch(`/variants/${sampleId}`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['variants'],
      });
      message.success('Variant updated');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('Unable to edit variant :' + error);
    },
  });
  return { editVariant };
};

export const useAddSample = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: addSample, data: newSampleData } = useMutation({
    mutationFn: ({ newData }) => {
      return authFetch.post(`/Variants/`, { ...newData });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Variants'] });
      message.success('New sample was created');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error('Error occurred while creating new sample :' + error);
    },
  });

  return { addSample, newSampleData };
};
