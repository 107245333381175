import { Button, Space, Input, Tag, Select, Divider } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import DebounceSelect from './DebounceFetcher';

const tagRender = (props, labels) => {
  const thisLabel = labels.find((label) => label.value === props.value);
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  if (!value) return null;
  return (
    <Tag
      color={thisLabel.color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const getColumnSearchProps = ({
  dataIndex,
  handleSearch,
  handleReset,
  FilterOptions,
  SearchOptions,
}) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {
        /* {console.log(dataIndex, SearchOptions[dataIndex])} */
        // console.log(FilterOptions)
      }
      {Object.keys(FilterOptions).includes(dataIndex) ? (
        <div className='ant-checkbox-group'>
          <Select
            tagRender={(props) => tagRender(props, FilterOptions[dataIndex])}
            mode='multiple'
            allowClear
            style={{ paddingBottom: '1rem', maxWidth: '480px' }}
            placeholder={`Please select ${dataIndex} items...`}
            defaultValue={SearchOptions[dataIndex]}
            onChange={(e) => setSelectedKeys(e)}
            filterOption={(inputValue, option) => {
              console.log(inputValue);
              console.log(option);
              return option.label
                .toLowerCase()
                .includes(inputValue.toLowerCase());
            }}
            options={FilterOptions[dataIndex]}
            dropdownStyle={{ width: '240px' }}
          />
          {/* {DebounceSelect({ defaulttags: SearchOptions[dataIndex]  })} */}
        </div>
      ) : (
        <Input
          placeholder={`Search ${dataIndex}...`}
          value={selectedKeys[0]}
          defaultValue={SearchOptions[dataIndex]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
      )}
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(dataIndex)}
          size='small'
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>

        <Button
          type='link'
          size='small'
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => {
    // console.log(SearchOptions[dataIndex]);
    let colr =
      SearchOptions[dataIndex] && SearchOptions[dataIndex].length > 0
        ? '#1677ff'
        : undefined;
    return Object.keys(FilterOptions).includes(dataIndex) ? (
      <FilterFilled
        style={{
          color:
            SearchOptions[dataIndex] && SearchOptions[dataIndex].length > 0
              ? '#1677ff'
              : undefined,
        }}
      />
    ) : (
      <SearchOutlined
        style={{
          color: SearchOptions[dataIndex] ? '#1677ff' : undefined,
        }}
      />
    );
  },
});

export default getColumnSearchProps;
