import { Timeline, Skeleton, Result, Empty } from 'antd';
import { useFetchPatientSamples } from '../../../assets/reactQuerry/samples.js';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const App = () => {
  let { id } = useParams();
  console.log(id);
  const {
    data: samples,
    isLoading,
    isError,
    error,
  } = useFetchPatientSamples({
    patientId: id,
  });

  if (isLoading) return <Skeleton active />;

  if (isError) return <Result status='500' title='500' subTitle={error} />;

  if (!samples.length) {
    return <Empty />;
  }

  //   const sortedSamples = samples.sort((a, b) => a.createdAt - b.createdAt);

  const sortedSamples = samples.sort((a, b) => {
    if (!a.originDate && !b.originDate)
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    if (!a.originDate && b.originDate)
      return new Date(b.createdAt).getTime() - new Date(a.originDate).getTime();
    if (a.originDate && !b.originDate)
      return new Date(b.originDate).getTime() - new Date(a.createdAt).getTime();
    if (a.originDate && b.originDate)
      return (
        new Date(b.originDate).getTime() - new Date(a.originDate).getTime()
      );
  });

  const items = sortedSamples.map((sample) => {
    return {
      color: 'green',
      label: dayjs(
        sample.originDate ? sample.originDate : sample.createdAt
      ).format('YYYY/MM/DD'),

      children: (
        <>
          <Link to={`../../samples/${sample.id}`} relative='path'>
            {sample.name}
          </Link>
          <p style={{ fontStyle: 'italic', marginTop: '0px' }}>
            {sample.comment}
          </p>
        </>
      ),
    };
  });

  return <Timeline mode='left' items={items} />;
};
export default App;
