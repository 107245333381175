import { Tooltip, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import ColumnSearchProps from '../../assets/getColumnSearchProps';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export default ({
  SearchOptions,
  handleSearch,
  handleReset,
  patientsIdName,
  sampleTags,
}) => {
  const sampleTagsForFilter = sampleTags.rows.map(({ id, text, color }) => ({
    value: id,
    label: text,
    color,
  }));

  const FilterOptions = {
    sex: [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
    ],
    PatientId: patientsIdName,
    SampleTags: sampleTagsForFilter,
  };

  const ColumnsSearchPropsDefault = {
    handleSearch,
    handleReset,
    FilterOptions,
    SearchOptions,
  };

  return [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 80,
      editable: false,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Patient',
      dataIndex: 'PatientId',
      width: 150,

      //   width: '15%',
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'PatientId',
      }),
      render: (patientId) => {
        const patientIdName = patientsIdName.find(
          (patient) => patient.value === patientId
        );
        return (
          <Link to={`../patients/${patientIdName?.value}`}>
            {patientIdName?.label}{' '}
          </Link>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'name',
      }),
      render: (text) =>
        SearchOptions?.name ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.name[0]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
    },
    {
      title: 'Bed',
      dataIndex: 'bed',
      width: 120,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'bed', //toto osetrit
      }),
      render: (_, line) => {
        return SearchOptions?.bed ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.bed[0]]}
            autoEscape
            textToHighlight={line.Bed?.name ? line.Bed.name.toString() : ''}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.Bed?.name}>
            {line.Bed?.name}
          </Tooltip>
        );
      },
    },
    // ),
    // },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: 200,
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'comment',
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (text) =>
        SearchOptions?.comment ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[SearchOptions.comment[0]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
    },
    {
      title: 'Date',
      dataIndex: 'originDate',
      width: 120,
      editable: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      render: (date) => {
        return <span>{date && dayjs(date).format('YYYY/MM/DD')}</span>;
      },
    },
    {
      title: 'Sample Tags',
      dataIndex: 'SampleTags',
      width: 200,
      editable: true,
      //   filters: sampleTagsForFilter,
      //   filterSearch: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'SampleTags',
      }),

      render: (tags) =>
        tags.map((tag) => {
          const { color, label, id } = tag;
          return (
            <Tag color={color} key={id}>
              {label.toUpperCase()}
            </Tag>
          );
        }),
    },
  ];
};
