import { Form, Input, Select, Tag } from 'antd';
import DebounceFetcher from '../../assets/DebounceFetcher';

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  data,
  children,
  ...restProps
}) => {
  const tagRender = (props) => {
    console.log(props);
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (!value) return null;
    return (
      <Tag
        color={'green'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const SelectFilt = (
    <Select
      showSearch
      placeholder='Choose a parent'
      optionFilterProp='children'
      filterOption={filterOption}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      options={data}
    />
  );

  //   const SelectTag = (
  //     <Select
  //       mode='tags'
  //       tagRender={tagRender}
  //       // defaultValue={'brym'}
  //       // defaultValue={record?.tags ?? []}
  //       // style={{ width: '100%' }}
  //       placeholder='Tags Mode'
  //       options={options}
  //     />
  //   );

  let inputNode;
  let rule;

  switch (dataIndex) {
    case 'motherId':
      dataIndex = 'motherId';
      inputNode = SelectFilt;
      break;
    case 'fatherId':
      dataIndex = 'fatherId';
      inputNode = SelectFilt;
      break;
    case 'sex':
      inputNode = (
        <Select
          options={[
            {
              value: 'male',
              label: 'Male',
            },
            {
              value: 'female',
              label: 'Female',
            },
          ]}
        />
      );

      break;
    case 'PatientTags':
      dataIndex = 'PatientTags2';
      inputNode = DebounceFetcher({ defaulttags: record.PatientTags });
      //   inputNode = <DebounceFetcher defaulttags={record.PatientTags} />;
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps} key={record?.id}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          key={record?.id}
          style={{
            margin: '-10px -5px -10px -5px',
            padding: 0,
          }}
          rules={rule}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
