import React, { useCallback } from 'react'
import classNames from 'classnames'
import type { ExtNode } from 'relatives-tree/lib/types'
import css from './FamilyNode.module.css'
import { Link } from 'react-router-dom'

interface FamilyNodeProps {
  node: ExtNode
  isRoot: boolean
  isHover?: boolean
  onClick: (id: string) => void
  onSubClick: (id: string) => void
  style?: React.CSSProperties
}

export const FamilyNode = React.memo(function FamilyNode({
  node,
  isRoot,
  isHover,
  onClick,
  onSubClick,
  style,
}: FamilyNodeProps) {
  const clickHandler = useCallback(() => onClick(node.id), [node.id, onClick])
  const clickSubHandler = useCallback(
    () => onSubClick(node.id),
    [node.id, onSubClick]
  )

  // console.log(node)
  // node.gender = node.gender === 'M' ? 'male' : 'female'

  return (
    <div className={css.root} style={style}>
      <div
        className={classNames(
          css[node.isSick ? 'sick' : 'healthy'],
          css.inner,
          css[node.gender],
          isRoot && css.isRoot,
          isHover && css.isHover
        )}
        onClick={clickHandler}
      >
        {/* {node.id} */}
        <div className={css.id}>
          <Link style={{ color: 'black' }} to={`../${node.id}`} relative="path">
            {node.name}
          </Link>
        </div>
      </div>
      {/* {node.hasSubTree && (
        <div
          className={classNames(css.sub, css[node.gender])}
          onClick={clickSubHandler}
        />
      )} */}
    </div>
  )
})
