import PatientsTable from './patientsTable';
import './index.css';

function PatientsPage() {
  return (
    <>
      <PatientsTable />
    </>
  );
}
export default PatientsPage;
