import { useState, useEffect } from 'react'
import { useFetchMyLogs } from '../../../assets/reactQuerry/general'
import { Result, Table, Tag } from 'antd'

const MethodFilter = [
  { text: 'GET', value: 'GET' },
  { text: 'DELETE', value: 'DELETE' },
  { text: 'POST', value: 'POST' },
  { text: 'PATCH', value: 'PATCH' },
]

const methodTranslate = {
  GET: 'viewed',
  DELETE: 'deleted',
  POST: 'added',
  PATCH: 'edited',
}

const methodColorDictionary = {
  GET: 'green',
  POST: 'blue',
  PATCH: 'orange',
  DELETE: 'red',
}

const reorderParams = (params) => {
  const { field, order, pagination, filters } = params

  const sort =
    field && order
      ? [field, order.toLowerCase() === 'ascend' ? 'ASC' : 'DESC'].join(':')
      : undefined
  return {
    sort,
    offset: (params.pagination?.current - 1) * params.pagination?.pageSize,
    limit: pagination.pageSize,
    filters: filters && filters.method ? filters : null,
  }
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Route',
    dataIndex: 'route',
  },
  {
    title: 'Action',
    dataIndex: 'method',
    render: (status) => (
      <Tag color={methodColorDictionary[status]}>
        {methodTranslate[status].toUpperCase()}
      </Tag>
    ),
    filters: MethodFilter,
    onFilter: (value, record) => record.method.indexOf(value) === 0,
  },

  {
    title: 'Old data',
    dataIndex: 'oldData',
    render: (data) => {
      return data
        .replace(/[{}"\\]/g, '')
        .replace(/[:]/g, '=')
        .replace(/[,]/g, ', ')
    },
  },
  {
    title: 'New data',
    dataIndex: 'newData',
    render: (newData) => {
      return newData
        .replace(/[{}"\\]/g, '')
        .replace(/[:]/g, '=')
        .replace(/[,]/g, ', ')
    },
  },
  {
    title: 'When',
    dataIndex: 'createdAt',
    render: (date) => {
      const newDate = new Date(date)
      return newDate.toLocaleString()
    },
    sorter: (a, b) => a.createdAt - b.createdAt,
  },
]

function Logs() {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination,
      },
      filters: { ...prevTableParams.filters, ...filters },
      ...sorter,
    }))
  }

  const params = reorderParams(tableParams)
  const { isLoading, isError, data: logs } = useFetchMyLogs(params)
  useEffect(() => {
    if (logs && logs.count !== tableParams.pagination.total) {
      handleTableChange({
        total: logs.count,
      })
    }
  }, [logs])

  if (isError)
    return (
      <Result
        status="500"
        title="500"
        subTitle="Omlouvame se, neco se porouchalo"
      />
    )

  return (
    <>
      <Table
        loading={isLoading}
        rowKey={(record) => record.id}
        bordered
        dataSource={logs?.rows}
        columns={columns}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
      />
    </>
  )
}
export default Logs
