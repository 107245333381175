import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Descriptions,
  Empty,
  Tag,
  Image,
  Button,
  Flex,
  Skeleton,
  message,
  Result,
} from 'antd';
import './index.css';
import { useFetchPatients } from '../../../assets/reactQuerry/patients';
import { Link } from 'react-router-dom';
import authFetch from '../../../assets/axios/authFetch';

const PatientDescription = () => {
  let { id } = useParams();
  const {
    isLoading,
    isError,
    data: patientsData,
  } = useFetchPatients({ id, full: false });
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPhotos([]);
    if (!isLoading && !isError && patientsData.photo_paths) {
      const imgNames = patientsData.photo_paths;
      const ids = Array(imgNames.length).fill(id);

      const fetchData = async () => {
        for (let i = 0; i < imgNames.length; i++) {
          try {
            const response = await authFetch.get(
              `/patients/${ids[i]}/image/${imgNames[i]}`,
              {
                responseType: 'arraybuffer',
              }
            );
            const base64Data = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            setPhotos((oldPhotos) => [
              ...oldPhotos,
              `data:image/png;base64,${base64Data}`,
            ]);
          } catch (error) {
            console.error(error);
            message.error(error.message);
          }
        }
      };

      fetchData(); // Invoke the fetchData function
    }
  }, [isLoading, patientsData]);

  if (isLoading) return <Skeleton active />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Sorry, something went wrong..'
      />
    );

  if (!patientsData.id) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nic nenalezeno!</span>}
      />
    );
  }

  const items = [
    {
      key: '1',
      label: 'Name',
      span: 2,
      children: `${patientsData.name}`,
    },
    {
      key: '2',
      label: 'Mother',
      //   span: 1,
      children: patientsData.mother ? (
        <Link to={`../${patientsData.mother.id}`} relative='path'>
          {patientsData.mother.name}
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: '3',
      label: 'Father',
      //   span: 1,
      children: patientsData.father ? (
        <Link to={`../${patientsData.father.id}`} relative='path'>
          {patientsData.father.name}
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: '4',
      label: 'Sex',
      span: 2,
      children: patientsData.sex,
    },
    {
      key: '5',
      label: 'Phenotype',
      span: 2,
      children:
        'etResponse. When the data is fetched, setResponse is called with the fetched data, updating response. Now, you can use response anywhere in your component. Remember that before the data is fetched, response will be null, so make sure to handle this case in your ' ||
        patientsData.phenotype,
    },
    {
      key: '7',
      label: 'Photos',
      span: 2,
      // span: { xs: 1, sm: 2, md: 3, lg: 3, xl: 2, xxl: 2 },
      children: (
        <Flex wrap='wrap' gap='small'>
          <Image.PreviewGroup preview='false'>
            {photos.map((data, index) => {
              return <Image key={index} width={100} src={data} />;
            })}
          </Image.PreviewGroup>
        </Flex>
      ),
    },

    {
      key: '6',
      label: 'Tags',
      span: 2,
      // span: { xs: 1, sm: 2, md: 3, lg: 3, xl: 2, xxl: 2 },

      children: (
        <>
          <Flex wrap='wrap' gap='small'>
            <Tag color='orange'>orange</Tag>
            <Tag color='gold'>gold</Tag>
            <Tag color='lime'>lime</Tag>
            <Tag color='green'>green</Tag>
            <Tag color='cyan'>cyan</Tag>
            <Tag color='blue'>blue</Tag>
            <Tag color='geekblue'>geekblue</Tag>
            <Tag color='purple'>purple</Tag>
          </Flex>
        </>
      ),
    },
  ];
  return (
    <div className='table'>
      <Descriptions
        layout='vertical'
        bordered={true}
        column={4}
        items={items}
      />
      <div className='centerButton'>
        <Button type='primary'>
          <Link to='edit' relative='path'>
            Edit
          </Link>
        </Button>
      </div>
    </div>
  );
};
export default PatientDescription;
