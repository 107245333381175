import SamplesTable from './SamplesTable';

import './index.css';

function SamplePage() {
  return (
    <>
      <SamplesTable></SamplesTable>
    </>
  );
}
export default SamplePage;
