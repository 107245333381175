import { useEffect } from 'react';
import io from 'socket.io-client';
import { App } from 'antd';
const server =
  process.env.NODE_ENV == 'production'
    ? 'https://avigena-se.dyn.cloud.e-infra.cz'
    : process.env.REACT_APP_SERVER_URL;

function WebSocketComponent() {
  const { notification } = App.useApp();

  useEffect(() => {
    // Connect to the WebSocket server
    const socket = io(`${server}`, {
      transports: ['websocket'], // Explicitly specify WebSocket transport
      query: { userId: 'TESTER' }, // Pass the user ID as a query parameter
    });
    // Event listener for connection established
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    // Event listener for incoming messages
    socket.on('message', (message) => {
      notification[message.type](message);
    });

    // Event listener for disconnection
    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    // Clean up function to disconnect WebSocket connection
    return () => {
      socket.disconnect();
    };
  }, []); // Run this effect only once on component mount

  // return <></>;
}

export default WebSocketComponent;
