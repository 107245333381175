import ReactFamilyTree from 'react-family-tree';
import React from 'react';
import { FamilyNode } from './familyTree/FamilyNode/FamilyNode.tsx';
import PinchZoomPan from './familyTree/PinchZoomPan/index.js';
import { useParams } from 'react-router-dom';
import { useFetchFamilyTree } from '../../../assets/reactQuerry/patients.js';
import { Skeleton, Empty } from 'antd';
// import dataFamilyTree from './FamilyData.json'

const WIDTH = 80;
const HEIGHT = 80;

export default React.memo(function FamilyTree() {
  let { id } = useParams();
  const { familyData, isLoading } = useFetchFamilyTree(id);

  if (isLoading) return <Skeleton />;
  if (!familyData.length) {
    return <Empty />;
  }

  return (
    <PinchZoomPan min={0.5} max={2.5} captureWheel>
      <ReactFamilyTree
        nodes={familyData}
        rootId={familyData[0].id}
        width={WIDTH}
        height={HEIGHT}
        renderNode={(node) => (
          <FamilyNode
            key={node.id}
            node={node}
            isRoot={node.id === id}
            onClick={(e) => {
              console.log(e);
            }}
            style={{
              width: WIDTH,
              height: HEIGHT,
              transform: `translate(${node.left * (WIDTH / 2)}px, ${
                node.top * (HEIGHT / 2)
              }px)`,
            }}
          />
        )}
      />
    </PinchZoomPan>
  );
});
