import { useEffect } from 'react';
import { useSessionContext } from '../../../assets/SessionContextProvider';

function GetTransciptsUseEffect(
  expandedKey,
  data,
  fetchData,
  setData,
  setLoading
) {
  useEffect(() => {
    const fetchDataForExpandedRow = async () => {
      setLoading(true);

      const newData = await fetchData(3);
      let updatedData = data.map((item) => {
        if (item.key === expandedKey) {
          // If the key matches the target item, update its subrows property
          return {
            ...item,
            subrows: newData,
          };
        }
        return item; // For other items, return them as is
      });
      setData(updatedData);
      setLoading(false);
    };
    const expandedRow = data.find((item) => item.key === expandedKey);
    if (expandedRow && expandedRow.subrows.length === 0) {
      //do not refetch data for already fetched subrows
      fetchDataForExpandedRow();
    }
  }, [expandedKey]);
}

function GetMainTableUseEffect(fetchData, setData, setLoading) {
  const { token } = useSessionContext();

  useEffect(() => {
    const fetchMainTable = async () => {
      const axiosResponse = await fetchData(token);
      const newData = axiosResponse.data;

      const renamedData = newData.map((data) => ({
        key: data.id,
        ...data,
        subrows: [],
      }));

      setData(renamedData);
      setLoading(false);
    };
    fetchMainTable();
  }, []);
}
export { GetTransciptsUseEffect, GetMainTableUseEffect };
