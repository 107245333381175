import { useState } from 'react';
import {
  Form,
  Table,
  Popconfirm,
  Typography,
  Input,
  Space,
  message,
  Select,
  Flex,
} from 'antd';
import EditableCell from './EditableCell';
import {
  GetTransciptsUseEffect,
  GetMainTableUseEffect,
} from './fetchingData/fetchTableVariants';
import { ExpandedTranscriptsRender } from './ExpandedTranscriptsRender';
import { allColumns } from './fetchingData/TableColumns';
import authFetch from '../../assets/axios/authFetch';

const { Search } = Input;

const fetchData = (count) => {
  // Simulate fetching data asynchronously
  return new Promise((resolve) => {
    setTimeout(() => {
      const data = [];
      for (let i = 0; i < count; i++) {
        data.push({
          key: `${Math.random()}`,
          name: (Math.random() + 1).toString(36).substring(7),
          age: Math.random(),
          address: `Address ${i}`,
          subrows: [],
        });
      }
      resolve(data);
    }, 1000); // Simulate a delay in fetching data
  });
};

const fetchDataAxios = (token) => {
  const response = authFetch.get('/variants/testing/');
  // const response = axios.get('http://localhost:8080/api/v1/variants/testing/', {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // })

  return response;
};

const BigTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [expandedKey, setexpandedKey] = useState();
  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState('');
  const [subform] = Form.useForm();
  const [editingSubKey, seteditingSubKey] = useState('');
  const [searchText, setSearchText] = useState('');
  const [columns, setColnames] = useState(allColumns);

  // Render the component only when 'data' is not empty
  const handleChange = (value) => {
    const retainedColumns = allColumns.filter((column) =>
      value.includes(column.dataIndex)
    );

    setColnames(retainedColumns);
  };
  GetMainTableUseEffect(fetchDataAxios, setData, setLoading); //here error

  GetTransciptsUseEffect(expandedKey, data, fetchData, setData, setLoading);

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) => {
      return (
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    })
  );

  const callExpandedTrancriptsRender = (record) => {
    return (
      <ExpandedTranscriptsRender
        record={record}
        subform={subform}
        seteditingSubKey={seteditingSubKey}
        editingSubKey={editingSubKey}
        data={data}
        setData={setData}
        loading={loading}
      />
    );
  };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
        message.success('Data uploaded'); // Display a success message
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
        message.success('Data uploaded'); // Display a success message
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error(`Data not uploaded ${errInfo}`); // Display an error message
    }
  };

  const colums_editCol = [
    ...columns,
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = colums_editCol.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Flex gap='middle' justify='center'>
        <Space style={{ padding: '1rem' }}>
          <Space.Compact size='large'>
            <Search
              placeholder='Vyhledejte varianty'
              value={searchText}
              enterButton='Hledat'
              allowClear
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={async () => {
                // Fetch data here based on the search query (value)
                const newData = await fetchData(4); // Replace fetchData with your data fetching logic
                setData(newData);
              }}
            />
          </Space.Compact>

          <Select
            mode='tags'
            style={{
              minWidth: '10rem',
              maxWidth: '40vw',
            }}
            placeholder='Tags Mode'
            defaultValue={columns.map((column) => column.title)}
            onChange={handleChange}
            options={allColumns.map((column) => {
              return { label: column.title, value: column.dataIndex };
            })}
          />
        </Space>
      </Flex>

      <Form form={form} component={false}>
        <Table
          // scroll={{ x: 1300 }}
          loading={loading}
          rowKey={(record) => record.id}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          expandable={{
            expandedRowRender: callExpandedTrancriptsRender,
            onExpand: (expanded, record) => {
              setexpandedKey(record.key);
            },
          }}
          rowClassName='editable-row'
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
};
export default BigTable;
