import { useQuery } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';

const LIMIT = 20;
const OFFSET = 0;
const SORT = '';
const FILTERS = '';

export const useFetchStats = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['generalStats'],
    queryFn: async () => {
      const { data } = await authFetch.get(`/general/stats`);
      return data;
    },
  });
  return { isLoading, isError, error, data };
};

export const useFetchMyLogs = ({
  offset = OFFSET,
  limit = LIMIT,
  filters = FILTERS,
  sort = SORT,
  isEnabled = true,
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['myLogs', limit, offset, sort, filters],
    queryFn: async () => {
      const { data } = await authFetch.get(
        `/general/logs/me?limit=${limit}&offset=${offset}&order=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      return data;
    },
    enabled: isEnabled,
  });

  return { isLoading, isError, error, data };
};

export const useFetchGroupLogs = ({
  offset = OFFSET,
  limit = LIMIT,
  filters = FILTERS,
  sort = SORT,
  isEnabled = true,
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['myLogs', limit, offset, sort, filters],
    queryFn: async () => {
      const { data } = await authFetch.get(
        `/general/logs/group?limit=${limit}&offset=${offset}&order=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      return data;
    },
    enabled: isEnabled,
  });

  return { isLoading, isError, error, data };
};
