var stringToColour = function (str) {
  var hash = 0
  let i = 0
  var colour = '#'
  for (i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  for (i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

function getContrastColor(hexColor) {
  // Remove the hash (#) character if it's included in the input
  const color = hexColor.replace('#', '')

  // Convert the hex color to RGB format
  const r = parseInt(color.slice(0, 2), 16)
  const g = parseInt(color.slice(2, 4), 16)
  const b = parseInt(color.slice(4, 6), 16)

  // Calculate the relative luminance of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Determine the contrast color (black or white) based on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF'
}

export { stringToColour, getContrastColor }
