import { createContext, useContext, useState, useEffect } from 'react'

const SessionContext = createContext()

export function SessionProvider({ children }) {
  const [token, setToken] = useState(
    sessionStorage.getItem('bearerToken') ||
      localStorage.getItem('bearerToken') ||
      null
  )

  const [userinfo, setUserinfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo')) ||
      JSON.parse(localStorage.getItem('userInfo')) ||
      null
  )
  useEffect(() => {
    // You can add code here to update the token if needed (e.g., token expiration handling)
  }, [])

  return (
    <SessionContext.Provider value={{ token, setToken, userinfo, setUserinfo }}>
      {children}
    </SessionContext.Provider>
  )
}

export function useSessionContext() {
  return useContext(SessionContext)
}
