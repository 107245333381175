import { useMutation } from '@tanstack/react-query'
import pubFetch from '../axios/pubFetch'
import { App } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSessionContext } from '../SessionContextProvider'

export const useLogin = () => {
  const { message } = App.useApp()
  const navigate = useNavigate()
  const { setToken, setUserinfo } = useSessionContext()

  const {
    mutate: logIn,
    isLoading,
    data,
  } = useMutation({
    mutationFn: ({ logindata }) =>
      pubFetch.post('users/login', { ...logindata }),
    onSuccess: ({ data }, variables) => {
      const { remember } = variables
      console.log(remember)
      if (remember) {
        localStorage.setItem('userInfo', JSON.stringify(data.userinfo))
        localStorage.setItem('bearerToken', data.token)
      } else {
        sessionStorage.setItem('bearerToken', data.token)
        sessionStorage.setItem('userInfo', JSON.stringify(data.userinfo))
      }

      message.success(data.message)
      setToken(data.token)
      setUserinfo(data.userinfo)
      navigate('/app')
    },
    onError: (error) => {
      console.log(error)
      message.error(error.response.data.error)
    },
  })
  return { logIn, isLoading }
}
