import { useEffect } from 'react'

import { App } from 'antd'

const ErrorMessage = ({ messageText }) => {
  const { message } = App.useApp()
  // Use useEffect to trigger the message.error outside of the render method
  useEffect(() => {
    message.error('K prohlizeni dat je nutne se prihlasit!')
  }, [messageText])

  return null // Return null as this component doesn't render anything in the DOM
}

export default ErrorMessage
