import { Tooltip, Tag, Space, Avatar } from 'antd';
import Highlighter from 'react-highlight-words';
import ColumnSearchProps from '../../assets/getColumnSearchProps';
import getSampleVariantTraitsColumns from './SampleVarTraitsColumns';

export default ({
  SearchOptions,
  handleSearch,
  handleReset,
  sampleId,
  setgeneDrawer,
}) => {
  const SampleVariantTraitsColumns = getSampleVariantTraitsColumns(sampleId);

  const FilterOptions = {
    'VarSeverityTags.id': [
      { label: 'Benign', value: 1, color: 'green' },
      { label: 'VUS', value: 2, color: 'blue' },
      { label: 'Likely benign', value: 3, color: 'lime' },
      { label: 'Likely pathogenic', value: 4, color: 'orange' },
      { label: 'Pathogenic', value: 5, color: 'volcano' },
    ],
  };

  const ColumnsSearchPropsDefault = {
    handleSearch,
    handleReset,
    FilterOptions,
    SearchOptions,
  };

  return [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 90,
      editable: false,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },

    {
      title: 'Chr',
      dataIndex: 'Loc.chr',
      render: (_, line) =>
        SearchOptions['Loc.chr'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['Loc.chr']}
            autoEscape
            textToHighlight={line.Loc?.chr.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.Variant?.Loc?.chr}>
            {line.Loc?.chr}
          </Tooltip>
        ),
      width: 80,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'Loc.chr',
      }),
    },
    {
      title: 'Pos',
      dataIndex: 'Loc.pos',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (_, line) =>
        SearchOptions['Loc.pos'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['Loc.pos']}
            autoEscape
            textToHighlight={line?.Loc?.pos.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line?.Loc?.pos}>
            {line.Loc?.pos}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'Loc.pos',
      }),
    },
    {
      title: 'Ref',
      dataIndex: 'main.ref',
      width: 100,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['main.ref'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['main.ref']}
            autoEscape
            textToHighlight={line?.ref.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line?.ref}>
            {line?.ref}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'main.ref',
      }),
    },
    {
      title: 'Alt',
      dataIndex: 'main.alt',
      width: 100,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['main.alt'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['main.alt']}
            autoEscape
            textToHighlight={line?.alt}
          />
        ) : (
          <Tooltip placement='topLeft' title={line?.alt}>
            {line?.alt}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'main.alt',
      }),
    },
    {
      title: 'Severity',
      dataIndex: 'VarSeverityTags.id',
      editable: true,
      width: 200,
      render: (_, line) =>
        line.VarComment.VarSeverityTags?.map((tag) => {
          const { color, text, id } = tag;
          return (
            <Tag color={color} key={id}>
              {text.toUpperCase()}
            </Tag>
          );
        }),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarSeverityTags.id',
      }),
    },

    {
      title: 'Gene',
      dataIndex: 'VarComment.gene',
      editable: true,
      render: (_, line) => <span>{line.VarComment?.gene}</span>,
      width: 100,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['VarComment.gene'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['VarComment.gene']}
            autoEscape
            textToHighlight={line.VarComment?.gene.toString()}
          />
        ) : (
          <a onClick={() => setgeneDrawer(line.VarComment?.gene)}>
            {line.VarComment?.gene}
          </a>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarComment.gene',
      }),
    },

    {
      title: 'cName',
      dataIndex: 'VarComment.cName',
      editable: true,

      width: 200,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['VarComment.cName'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['VarComment.cName']}
            autoEscape
            textToHighlight={line.VarComment?.cName.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.VarComment?.cName}>
            {line.VarComment?.cName}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarComment.cName',
      }),
    },
    {
      title: 'pName',
      dataIndex: 'VarComment.pName',
      editable: true,

      width: 200,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['VarComment.pName'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['VarComment.pName']}
            autoEscape
            textToHighlight={line.VarComment?.pName.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.VarComment?.pName}>
            {line.VarComment?.pName}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarComment.pName',
      }),
    },

    {
      title: 'Transcript',
      dataIndex: 'VarComment.transcript',
      editable: true,
      width: 200,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['VarComment.transcript'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['VarComment.transcript']}
            autoEscape
            textToHighlight={line.VarComment?.transcript.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.VarComment?.transcript}>
            {line.VarComment?.transcript}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarComment.transcript',
      }),
    },
    {
      title: 'Comment',
      dataIndex: 'VarComment.comment',
      editable: true,

      width: 200,
      ellipsis: {
        showTitle: false,
      },

      render: (_, line) =>
        SearchOptions['VarComment.comment'] ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={SearchOptions['VarComment.comment']}
            autoEscape
            textToHighlight={line.VarComment?.comment.toString()}
          />
        ) : (
          <Tooltip placement='topLeft' title={line.VarComment?.comment}>
            {line.VarComment?.comment}
          </Tooltip>
        ),
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'VarComment.comment',
      }),
    },
    {
      title: 'Links',
      dataIndex: 'VarComment.links',
      width: 200,

      editable: true,
      ellipsis: true,
      render: (_, line) =>
        line.VarComment?.links && (
          <Space wrap>
            {line.VarComment?.links.map((link) => (
              <a key={link} href={link}>
                {link}
              </a>
            ))}
          </Space>
        ),
    },
    {
      title: 'Sample count',
      dataIndex: 'samples',
      width: 150,

      render: (_, line) => (
        <Tooltip
          placement='topLeft'
          title={`${line.samplesVariantCount} samples`}
        >
          {line.samplesVariantCount}
        </Tooltip>
      ),
    },
    {
      title: 'Proband count',
      dataIndex: 'probands',
      width: 150,

      render: (_, line) => (
        <Tooltip
          placement='topLeft'
          title={`${line.patientsVariantCount} probands`}
        >
          {line.patientsVariantCount}
        </Tooltip>
      ),
    },
    ...SampleVariantTraitsColumns,
  ];
};
