import { useState, useEffect } from 'react';
import {
  useFetchSampleTags,
  useEditSampleTag,
  useAddSampleTag,
  useDeleteSampleTag,
} from '../../../assets/reactQuerry/sampleTags';
import {
  Skeleton,
  Result,
  Empty,
  Table,
  Tag,
  ColorPicker,
  Button,
  Input,
  Flex,
  Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Typography } from 'antd';
import reorderParams from './assets/reorderParams';
import EditableCell from './assets/EditableCell';

function SampleTags() {
  const { addSampleTag } = useAddSampleTag();
  const [searchText, setSearchText] = useState('');
  const [finalColor, setFinalColor] = useState('#ccc');
  const { editSampleTag } = useEditSampleTag();
  const [editingKey, setEditingKey] = useState('');
  const { deleteSampleTag } = useDeleteSampleTag();

  const [form] = Form.useForm();

  const isEditing = (record) => record.id === editingKey;

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = () => {
    setSearchText('');
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      filters: {},
    }));
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    field: 'id', //for not refetching, default descend sort id
    order: 'descend',
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      filters: { ...prevTableParams.filters, ...filters },
      ...sorter,
    }));
  };

  const params = reorderParams(tableParams, handleReset);
  const { isLoading, isError, data: tags } = useFetchSampleTags(params);

  useEffect(() => {
    if (tags && tags.count !== tableParams.pagination.total) {
      handleTableChange({
        total: tags.count,
      });
    }
  }, [tags]);

  if (isLoading) return <Skeleton />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Omlouvame se, neco se porouchalo'
      />
    );

  if (!tags) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nic nenalezeno!</span>}
      />
    );
  }

  // const { setEditingKey, setSearchText, mergedCols } = mergedColumnsCb({
  //   form,
  // });
  // const mergedColumns = mergedCols();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={`Search dataIndex`}
          value={selectedKeys[0] || searchText}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
  });

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (id) => {
    const data = await form.validateFields();
    editSampleTag({
      tagId: id,
      newData: { text: data.text },
    });

    setEditingKey('');
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Tag',
      dataIndex: 'text',
      editable: true,

      render: (_, { color, text }) => <Tag color={color}>{text}</Tag>,
      ...getColumnSearchProps(),
    },
    {
      title: 'Color',
      dataIndex: 'color',
      render: (_, { id, color }) => (
        <ColorPicker
          value={color}
          size='large'
          onOpenChange={(e) => {
            !e &&
              editSampleTag({
                tagId: id,
                newData: { color: finalColor },
              });
          }}
          onChangeComplete={(color) => {
            setFinalColor(color.toHexString());
          }}
        />
      ),
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '25%',

      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space size='middle'>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              type='danger'
              onClick={() => deleteSampleTag(record.id)}
            >
              Delete tag
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Flex gap='middle' justify='center' align='center'>
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <Button
            onClick={async () => {
              const newTag = await addSampleTag({
                newData: { text: 'sample tag' },
              });
              edit({ ...newTag.data });
            }}
          >
            Add new tag
          </Button>
          <Button onClick={handleReset}>Clear filters</Button>
        </Space>
      </Flex>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          rowKey={(record) => record.id}
          bordered
          dataSource={tags?.rows}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </Form>
    </>
  );
}
export default SampleTags;
