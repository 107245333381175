import { useState } from 'react';
import { Col, Row, Drawer, Skeleton, Table } from 'antd';
import DemoDonut from './Donut';
import { useFetchVariantCoverage } from '../../assets/reactQuerry/variants';
import { Link } from 'react-router-dom';

const cols = [
  {
    title: 'Sample',
    dataIndex: 'sample',
    key: 'sample',
    render: (_, line) => {
      return (
        <Link to={`/app/samples/${line.sampleId}`}>{line.sampleName}</Link>
      );
    },
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    render: (_, line) => {
      return (
        <Link to={`/app/samples/${line.sampleId}`}>{line.patientName}</Link>
      );
    },
  },
  {
    title: 'Accessibility',
    dataIndex: 'isAccessible',
    key: 'isAccessible',
    render: (text) => text && <a>yes</a>,
  },
  {
    title: 'Variant present',
    dataIndex: 'hasVariant',
    key: 'hasVariant',
    render: (text) => text && <a>yes</a>,
  },
];

const DetailDrawer = ({ line, drawerOpen, setDrawerOpen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isShown, setIsShown] = useState(false);

  const {
    isLoading: isLoadingCoverage,
    isError,
    error,
    data: coverageData,
  } = useFetchVariantCoverage({ id: line.id });

  const onClose = () => {
    setDrawerOpen(false);
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
    setIsShown(true);
  };

  return (
    <>
      <Drawer
        title='Variant'
        closable={false}
        onClose={onClose}
        open={drawerOpen}
        size='large'
      >
        <Row gutter={16}>
          <Col span={12}>
            <DemoDonut
              data={{
                hasVariant: coverageData?.samplesVariantCount,
                total: coverageData?.samplesOverlapCount,
              }}
              isLoading={isLoading}
              colors={['blue', 'grey']}
              text='Occurrence of samples'
            />
          </Col>
          <Col span={12}>
            <DemoDonut
              data={{
                hasVariant: coverageData?.patientsVariantCount,
                total: coverageData?.patientsOverlapCount,
              }}
              isLoading={isLoading}
              colors={['green', 'grey']}
              text='Occurrence of probands'
            />
          </Col>
        </Row>
        <Table
          rowKey={(record) => record.sampleId}
          loading={isLoadingCoverage}
          columns={cols}
          dataSource={coverageData?.samples}
          size='small'
        />

        {isLoading && <Skeleton />}
        <iframe
          style={{
            width: '100%',
            height: '100%',
            display: isShown || 'none',
          }}
          onLoad={handleIframeLoad}
          //   src={`https://genebe.net/gene/hg38/${line.VarComment?.gene}`}
          src={`https://genebe.net/variant/hg38/chr${line.Loc.chr}:${line.Loc.pos}-${line.ref}>${line.alt}`}
        ></iframe>
      </Drawer>
    </>
  );
};
export default DetailDrawer;
