import React from 'react'
import { Button, Form, Input, Select, Space } from 'antd'
const { Option } = Select
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

function RegisterPage() {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    console.log(values)
  }
  const onReset = () => {
    form.resetFields()
  }

  const validateMessages = {
    required: '${label} je povinny!',
    types: {
      email: 'Toto neni platny email!',
    },
  }

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{
        margin: '10% auto',

        maxWidth: 600,
      }}
    >
      <Form.Item
        name="name"
        label="Jmeno"
        rules={[
          {
            required: true,
            message: 'Jmeno je povinne!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="surname"
        label="Prijmeni"
        rules={[
          {
            required: true,
            message: 'Prijmeni je povinne!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        name="email"
        label="Email"
        rules={[
          { type: 'email' },
          {
            required: true,
            message: 'Zadejte svuj E-mail!',
          },
        ]}
      > */}
      {/* <Input /> */}
      {/* </Form.Item> */}
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { type: 'email', message: 'Toto neni platny email!' },
          {
            required: true,
            message: 'Zadejte svuj E-mail!',
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="confirmEmail"
        label="email znovu znovu"
        dependencies={['email']}
        hasFeedback
        rules={[
          { type: 'email', message: 'Toto neni platny email!' },
          {
            required: true,
            message: 'Zadejte znovu svuj email!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Zadane emaily se neshoduji!'))
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Heslo"
        rules={[
          {
            required: true,
            message: 'Zadejte sve heslo!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Heslo znovu"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Zadejte znovu sve heslo!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Zadana hesla nejsou stejna!'))
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <Button type="primary" htmlType="submit">
            Odeslat
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Vymazat
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
export default RegisterPage
