import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const ID = 'all';
const LIMIT = 10;
const OFFSET = 0;
const SORT = '';
const FILTERS = {};

export const useFetchPatientSamples = ({
  patientId = ID,
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
}) => {
  // console.log(limit)
  // console.log(sort)
  const { isLoading, data, isError, error, isFetchedAfterMount } = useQuery({
    queryKey: ['samples', 'patient', patientId],
    queryFn: async () => {
      const { data } = await authFetch.get(`/samples/patient/${patientId}`);
      return data;
    },
  });
  return { isLoading, isError, error, data, isFetchedAfterMount };
};

export const usePrefetchSamples = ({
  id = ID,
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
}) => {
  return {
    queryKey: ['samples', id, limit, offset, sort, filters],
    queryFn: async () => {
      const response = await authFetch.get(
        `/samples/patient/${id}?limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );

      return response.data; // Adjust this based on the actual structure of your API response
    },
  };
};

export const useFetchSamples = ({
  id = '', // = ID,
  limit = LIMIT,
  offset = OFFSET,
  sort = SORT,
  filters = FILTERS,
  full = true,
  isEnabled = true,
}) => {
  // console.log(limit)
  // console.log(sort)
  const { isLoading, data, isError, error, isFetchedAfterMount } = useQuery({
    queryKey: ['samples', id, limit, offset, sort, filters],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;
      console.log(
        `/samples?sampleId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      const { data } = await authFetch.get(
        `/samples?sampleId=${id}&limit=${limit}&offset=${offset}&orderBy=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      // return data
      return full ? data : data.data[0];
    },
    enabled: isEnabled,
  });
  return { isLoading, isError, error, data, isFetchedAfterMount };
};

export const useEditSample = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: editSample } = useMutation({
    mutationFn: ({ sampleId, newData }) => {
      return authFetch.patch(`/samples/${sampleId}`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['samples'],
      });
      message.success('Sample updated');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('Unable to edit sample :' + error);
    },
  });
  return { editSample };
};

export const useAddSample = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: addSample, data: newSampleData } = useMutation({
    mutationFn: ({ newData }) => {
      return authFetch.post(`/samples/`, { ...newData });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['samples'] });
      message.success('New sample was created');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error('Error occurred while creating new sample :' + error);
    },
  });

  return { addSample, newSampleData };
};

export const useDeleteSample = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: deleteSample, isLoading: deleteSampleLoading } = useMutation({
    mutationFn: (sampleId) => {
      console.log(sampleId);
      console.log(`/samples/${sampleId}`);

      return authFetch.delete(`/samples/${sampleId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['varComments'], ['samples'], ['variants']);
      message.success('Sample was deleted!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error('Error occurred while deleting sample! :' + error);
    },
  });
  return { deleteSample, deleteSampleLoading };
};

export const useUploadTableToSample = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: uploadTableToSample } = useMutation({
    mutationFn: ({ sampleId, SampleTagIds }) => {
      return authFetch.post(`/samples/upload/${sampleId}/`, { SampleTagIds });
    },
    onSuccess: (_, variables) => {
      const { sampleId } = variables;
      // queryClient.invalidateQueries({
      //   queryKey: ['samples'],
      // });
      message.success('Table successfully uploaded!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('Unable to upload variant table to sample :' + error);
    },
  });
  return { uploadTableToSample };
};

export const usePushTableToDB = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: pushTableToDB } = useMutation({
    mutationFn: (sampleId) => {
      return authFetch.post(`/samples/${sampleId}/tableToDB`, { sampleId });
    },
    onSuccess: (_, variables) => {
      const { sampleId } = variables;
      queryClient.invalidateQueries([
        { queryKey: ['samples'] },
        { queryKey: ['variants'] },
      ]);
      message.success('Parsing table to DB...');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('Unable to start parsing table to DB...:' + error);
    },
  });
  return { pushTableToDB };
};

export const useSetTagsToSample = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: setTagsToSample } = useMutation({
    mutationFn: ({ sampleId, SampleTagIds }) => {
      return authFetch.post(`/samples/${sampleId}/tag`, { SampleTagIds });
    },
    onSuccess: (_, variables) => {
      const { sampleId } = variables;
      queryClient.invalidateQueries({
        queryKey: ['samples'],
      });
      message.success('Sample tags updated');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('Unable to edit sample tag :' + error);
    },
  });
  return { setTagsToSample };
};
