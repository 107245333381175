import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { App } from 'antd';

import authFetch from '../axios/authFetch';
const ID = 'all';

// export const useFetchMyGroup = ({ id = ID }) => {
//   const { isLoading, data, isError, error } = useQuery({
//     queryKey: ['mygroup'],
//     queryFn: async () => {
//       const { data } = await authFetch.get(`/groups/my`);
//       return data;
//     },
//   });
//   return { isLoading, isError, error, data };
// };

export const useFetchGroups = ({ id = ID }) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['groups', id],
    queryFn: async () => {
      const { data } = await authFetch.get(`/groups/get/${id}`);
      return data;
    },
  });
  return { isLoading, isError, error, data };
};

export const useFetchGroupMembers = ({ groupId = ID }) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['groupMembers', groupId],
    queryFn: async () => {
      const { data } = await authFetch.get(`/groups/members/${groupId}`);
      return data;
    },
  });

  return { isLoading, isError, error, data };
};

export const useFetchMyGroupAccesses = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['myGroupAccesses'],
    queryFn: async () => {
      const { data } = await authFetch.get(`/groups/accesses/`);
      return data;
    },
  });

  return { isLoading, isError, error, data };
};

export const useGiveAccess = (groupId) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: giveGroupAccess } = useMutation({
    mutationFn: (groupId) => {
      return authFetch.post(`/groups/access/${groupId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myGroupAccesses'] });
      message.success('Permission to access your group was granted!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error(
        'Error occured while granting group permissions! :' + error
      );
    },
  });

  return { giveGroupAccess };
};

export const useCancelAccess = (groupId) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: cancelGroupAccess } = useMutation({
    mutationFn: (groupId) => {
      return authFetch.delete(`/groups/access/${groupId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myGroupAccesses'] });
      message.success('Cancellation of access to your group was successful!');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      message.error('Error occurred while canceling group access! :' + error);
    },
  });

  return { cancelGroupAccess };
};
