import { useState, useEffect } from 'react';
import { Skeleton, Result, Empty, Table, Button, Flex, Space } from 'antd';
import { Form, Popconfirm, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import {
  useFetchBeds,
  useDeleteBed,
  useEditBed,
} from '../../assets/reactQuerry/beds';
import { useFetchUsers } from '../../assets/reactQuerry/users';

import reorderParams from '../../assets/reorderParams';
import EditableCell from './assets/EditableCell';
import BedUpload from './assets/BedUpload';
import ColumnSearchProps from '../../assets/getColumnSearchProps';

function BedsPage() {
  const [SearchOptions, setSearchOptions] = useState('');
  const { data: users, isLoading: isUsersLoading } = useFetchUsers();
  const [editingKey, setEditingKey] = useState('');
  const { deleteBed } = useDeleteBed();
  const { editBed } = useEditBed();

  const [form] = Form.useForm();
  const isEditing = (record) => record.id === editingKey;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchOptions((old) => {
      const updatedOptions = { ...old, [dataIndex]: selectedKeys };
      setTableParams((prevTableParams) => ({
        //needs to be update filter here to have the latest updatedOptions
        ...prevTableParams,
        filters: { ...prevTableParams.filters, ...updatedOptions },
      }));
      return updatedOptions; // Return the updated options
    });
  };

  const handleReset = (dataIndex) => {
    if (dataIndex) {
      setSearchOptions((old) => {
        const updatedOptions = { ...old, [dataIndex]: null };
        console.log(updatedOptions);
        setTableParams((prevTableParams) => ({
          //needs to be update filter here to have the latest updatedOptions
          ...prevTableParams,
          filters: { ...updatedOptions },
        }));
        return updatedOptions; // Return the updated options
      });
    } else {
      setSearchOptions('');
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        filters: {},
      }));
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    field: 'id', //for not refetching, default descend sort id
    order: 'DESC',
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      ...sorter,
    }));
  };

  const params = reorderParams(tableParams, handleReset);
  const { isLoading, isError, data: beds } = useFetchBeds(params);

  useEffect(() => {
    if (beds && beds.count !== tableParams.pagination.total) {
      handleTableChange({
        total: beds.count,
      });
    }
  }, [beds]);

  if (isLoading || isUsersLoading) return <Skeleton />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Sorry, something went wrong...'
      />
    );

  if (!beds) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nothing found!</span>}
      />
    );
  }

  const usersForFilter = users.map(({ id, fullName }) => ({
    value: id,
    label: fullName,
  }));

  const FilterOptions = {
    UserId: usersForFilter,
  };
  const ColumnsSearchPropsDefault = {
    handleSearch,
    handleReset,
    FilterOptions,
    SearchOptions,
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (id) => {
    const data = await form.validateFields();
    editBed({ bedId: id, newData: data });
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 60,
      //   ...ColumnSearchProps({
      //     ...ColumnsSearchPropsDefault,
      //     dataIndex: 'id',
      //   }),
    },
    {
      title: 'User',
      dataIndex: 'UserId',
      width: 200,
      render: (userId) => {
        const user = users.find((user) => user.id === userId);

        return (
          <span>
            {user ? (
              <Link to={`../users/${user.id}`}>{user.fullName}</Link>
            ) : (
              'User not found'
            )}
          </span>
        );
      },
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'UserId',
      }),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'name',
      }),
    },
    {
      title: 'Description',
      editable: true,
      dataIndex: 'description',
      ...ColumnSearchProps({
        ...ColumnsSearchPropsDefault,
        dataIndex: 'description',
      }),
    },

    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: (a, b) => a.id - b.id,

      render: (date) => {
        return <span>{date && dayjs(date).format('YYYY/MM/DD')}</span>;
      },
      //   ...ColumnSearchProps({
      //     ...ColumnsSearchPropsDefault,
      //     dataIndex: 'createdAt',
      //   }),
    },

    {
      title: 'operation',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right',

      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Flex wrap='wrap' gap='small'>
            {editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(record.id)}
                  style={{
                    display: 'block',
                  }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                  <Typography.Link type='danger'>Cancel</Typography.Link>
                </Popconfirm>
              </span>
            ) : (
              <>
                <Typography.Link
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
                <Popconfirm
                  title='Sure to delete?'
                  onConfirm={() => deleteBed(record.id)}
                >
                  <Typography.Link type='danger'>Delete</Typography.Link>
                </Popconfirm>
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Flex gap='middle' justify='center' align='center'>
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <BedUpload />

          <Button onClick={() => handleReset()}>Clear filters</Button>
        </Space>
      </Flex>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          rowKey={(record) => record.id}
          bordered
          dataSource={beds?.rows}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </Form>
    </>
  );
}
export default BedsPage;
