import { Button, Result } from 'antd'
import { useRouteError, Link } from 'react-router-dom'

const App = ({ subtitle = 'Tato stranka neexistuje' }) => {
  const error = useRouteError()
  console.error(error)

  return (
    <Result
      status="404"
      title="404"
      subTitle={subtitle}
      extra={
        <Button type="primary">
          <Link to=".." relative="path">
            Zpatky
          </Link>
          {/* <Link to={`/`}>Domu</Link> */}
        </Button>
      }
    />
  )
}

export default App
