import { Tooltip } from 'antd'

export let allColumns = [
  {
    title: 'LocId',
    dataIndex: 'LocId',
    width: '10%',
    editable: false,
    sorter: (a, b) => a.LocId - b.LocId,
  },
  {
    title: 'ref',
    dataIndex: 'ref',
    width: '100',
    editable: false,
  },
  {
    title: 'alt',
    dataIndex: 'alt',
    width: '10%',
    editable: false,
    filters: [
      {
        text: 'Joe',
        value: 'Joe',
      },
      {
        text: 'Category 1',
        value: 'Category 1',
      },
      {
        text: 'Category 2',
        value: 'Category 2',
      },
    ],
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (value, record) => record.name.startsWith(value),
  },
  {
    title: 'patientIds',
    dataIndex: 'patientIds',
    width: '20%',
    editable: false,
  },
  {
    title: 'sampleIdsIds',
    dataIndex: 'sampleIdsIds',
    width: '20%',
    editable: false,
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    width: '10%',
    editable: false,
    ellipsis: {
      showTitle: false,
    },
    render: (address) => (
      <Tooltip placement="topLeft" title={address}>
        {address}
      </Tooltip>
    ),
  },
]
