import React, { useState } from 'react';
import { Button, Steps, App, Flex } from 'antd';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const NewSampleFormStepper = () => {
  const { message } = App.useApp();

  const [formDataUpload, setFormDataUpload] = useState(new FormData());

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Sample Info',
      content: (
        <Step1
          formDataUpload={formDataUpload}
          setFormDataUpload={setFormDataUpload}
          next={next}
        />
      ),
    },
    {
      title: 'Upload Variant Table',
      content: (
        <Step2
          formDataUpload={formDataUpload}
          setFormDataUpload={setFormDataUpload}
          next={next}
          prev={prev}
        />
      ),
    },
    {
      title: 'Final Check',
      content: (
        <Step3 formDataUpload={formDataUpload} next={next} prev={prev} />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    margin: '16px 0 0 auto',
    marginTop: 16,
  };
  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        <Flex justify='space-between'>
          {current === steps.length - 1 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {/* {current > 0 && current < steps.length - 1 && (
            <Button
              style={{ marginLeft: 'auto' }}
              type='primary'
              onClick={() => next()}
            >
              Next
            </Button>
          )}  */}
          {/* {current === steps.length - 1 && (
            <Button
              type='primary'
              onClick={() => message.success('Processing complete!')}
            >
              Done
            </Button>
          )} */}
        </Flex>
      </div>
    </>
  );
};
export default NewSampleFormStepper;
