import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  Form,
  Table,
  Popconfirm,
  Typography,
  Space,
  Flex,
  Button,
  Skeleton,
  Select,
  App,
  Drawer,
} from 'antd';
import EditableCell from './EditableCell';
import getVariantColumns from './VariantsColumns';
import TranscriptsRender from './expandedTable/TranscriptsRender';

import { useFetchVariants } from '../../assets/reactQuerry/variants';
import { useEditVarComment } from '../../assets/reactQuerry/varComments';

import reorderParams from '../../assets/reorderParams';

import { NotExists } from '../errors';
import DetailDrawer from './DetailDrawer';

const VariantsTable = ({ sampleId }) => {
  //   const { isLoadingIds, patientsIdName } = useFetchPatientIds('all');
  const [form] = Form.useForm();
  const [subform] = Form.useForm();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [geneDrawer, setgeneDrawer] = useState(false);

  const [editingKey, setEditingKey] = useState('');
  const [editingSubKey, setEditingSubKey] = useState('');
  const [SearchOptions, setSearchOptions] = useState('');
  const [filteredColumns, setFilteredColumns] = useState([]);
  //   const [expandedKey, setExpandedKey] = useState();
  const { editVarComment } = useEditVarComment();

  const { message } = App.useApp();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchOptions((old) => {
      const updatedOptions = { ...old, [dataIndex]: selectedKeys };
      setTableParams((prevTableParams) => ({
        //needs to be update filter here to have the latest updatedOptions
        ...prevTableParams,
        filters: { ...prevTableParams.filters, ...updatedOptions },
      }));
      return updatedOptions; // Return the updated options
    });
  };

  const handleReset = (dataIndex) => {
    if (dataIndex) {
      setSearchOptions((old) => {
        const updatedOptions = { ...old, [dataIndex]: null };
        setTableParams((prevTableParams) => ({
          //needs to be update filter here to have the latest updatedOptions
          ...prevTableParams,
          filters: { ...updatedOptions },
        }));
        return updatedOptions; // Return the updated options
      });
    } else {
      setSearchOptions('');
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        filters: {},
      }));
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    field: 'id', //for not refetching, default descend sort id
    order: 'DESC',
  });

  let params = reorderParams(tableParams, handleReset);
  params = sampleId ? { ...params, sampleId } : params;
  console.log(params);

  //   const SampleColumns = getVariantColumns(setTableParams);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      ...sorter,
    }));
  };

  const {
    isLoading,
    isError,
    error,
    data: variantData,
  } = useFetchVariants(params);

  useEffect(() => {
    if (variantData && variantData.count !== tableParams.pagination.total) {
      handleTableChange({
        total: variantData.count,
      });
    }
  }, [variantData]); // Only run this effect when `data` changes

  if (isError) {
    message.error('Unable to load data');
    console.log(error);
    return (
      <NotExists subtitle={error?.response?.data ?? 'Unable to load data'} />
    );
  }

  if (isLoading) {
    return <Skeleton />;
  }

  const callExpandedTranscriptsRender = (record) => {
    return (
      <TranscriptsRender
        record={record}
        subform={subform}
        seteditingSubKey={setEditingSubKey}
        editingSubKey={editingSubKey}
      />
    );
  };

  const SampleColumns = getVariantColumns({
    SearchOptions,
    handleSearch,
    handleReset,
    sampleId,
    setgeneDrawer,
  });

  const handleColumnChange = (value, smth) => {
    const retainedColumns = SampleColumns.filter((column) =>
      value.includes(column.dataIndex)
    );

    setFilteredColumns(retainedColumns);
  };

  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      originDate: record.originDate ? dayjs(record.originDate) : null,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editVarComment({ id: record.VarComment.id, newData: row });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error(`Data not uploaded ${errInfo}`); // Display an error message
    }
  };

  const colsToUse = filteredColumns[0] ? filteredColumns : SampleColumns;
  const colums_editCol = [
    ...colsToUse,
    {
      dataIndex: 'operation',
      width: 110,

      fixed: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                display: 'block',
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <Typography.Link type='danger'>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Flex wrap='wrap' gap='small'>
            <a
              onClick={() => {
                setDrawerOpen(record.id);
              }}
            >
              Detail
            </a>
            {drawerOpen === record.id && (
              <DetailDrawer
                line={record}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}

            <Typography.Link
              type='warning'
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = colums_editCol.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Drawer
        size='large'
        closable={false}
        onClose={() => {
          setgeneDrawer(false);
        }}
        open={geneDrawer}
      >
        <iframe
          style={{
            width: '100%',
            height: '100%',
          }}
          src={`https://genebe.net/gene/hg38/${geneDrawer}`}
        ></iframe>
      </Drawer>

      <Flex gap='middle' justify='center' align='center'>
        <Space style={{ padding: '1rem' }}>
          <Button onClick={() => handleReset()}>Clear filters</Button>
          <Select
            mode='tags'
            style={{
              minWidth: '10rem',
              maxWidth: '40vw',
            }}
            placeholder='Tags Mode'
            defaultValue={colsToUse.map((column) => column.dataIndex)}
            onChange={handleColumnChange}
            options={SampleColumns.map((column) => {
              return { label: column.title, value: column.dataIndex };
            })}
          />
        </Space>
      </Flex>

      <Form form={form} component={false}>
        <Table
          loading={isLoading}
          rowKey={(record) => record.id}
          components={{
            body: {
              cell: (cell) => (
                <EditableCell
                  {...cell}
                  // data={{ patientsIdName }}
                />
              ),
            },
          }}
          bordered
          dataSource={variantData.data}
          columns={mergedColumns}
          expandable={{
            expandedRowRender: callExpandedTranscriptsRender,
            // onExpand: (expanded, record) => {
            //   //   setExpandedKey(record.key);
            // },
          }}
          scroll={{
            x: '1300',
          }}
          rowClassName='editable-row'
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </Form>
    </div>
  );
};
export default VariantsTable;
