import { Form, Input, Select, Tag, Space } from 'antd';

const severityFilter = [
  { label: 'Benign', value: 1, color: 'green' },
  { label: 'VUS', value: 2, color: 'blue' },
  { label: 'Likely benign', value: 3, color: 'lime' },
  { label: 'Likely pathogenic', value: 4, color: 'orange' },
  { label: 'Pathogenic', value: 5, color: 'volcano' },
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const tagRender = (props, labels) => {
    const thisLabel = labels.find((label) => label.label === props.label);
    const { label, value, color, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (!value) return null;
    return (
      <Tag
        color={thisLabel?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const SelectSeverityTags = (
    <Select
      mode='tags'
      tagRender={(props) => tagRender(props, severityFilter)}
      defaultValue={
        record?.VarSeverityTags?.map((tag) => ({
          label: tag.text,
          value: tag.id,
          color: tag.color,
        })) ?? []
      }
      dropdownStyle={{ width: 'auto' }}
      options={severityFilter}
    />
  );

  let inputNode;
  let rule;

  switch (dataIndex) {
    case 'VarSeverityTags':
      dataIndex = 'severityTags';
      inputNode = SelectSeverityTags;
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: '-10px -5px -10px -5px',
            padding: 0,
          }}
          rules={rule}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
