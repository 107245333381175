// import { useParams } from 'react-router-dom'
import { Descriptions, Empty, Tag, Button, Flex, Skeleton, Result } from 'antd'
// import './index.css'
import { useFetchMe } from '../../../assets/reactQuerry/users'
import { Link } from 'react-router-dom'

const PatientDescription = () => {
  //   let { id } = useParams()
  const {
    isLoading,
    isError,
    error,
    data: userData,
  } = useFetchMe({ isEnabled: false })
  //   const token = useSessionContext()
  //   console.log(token)

  if (isLoading) return <Skeleton />

  if (isError)
    return (
      <Result
        status="500"
        title="500"
        subTitle="Omlouvame se, neco se porouchalo"
      />
    )

  if (!userData.id) {
    return (
      <Empty
        imageStyle={{ height: 100 }}
        description={<span>Nic nenalezeno!</span>}
      />
    )
  }

  const items = [
    {
      key: '1',
      label: 'First name',
      children: userData.firstName,
    },
    {
      key: '2',
      label: 'Second name',
      children: userData.lastName,
    },
    {
      key: '3',
      label: 'email',
      children: userData.email,
    },
    {
      key: '4',
      label: 'Group name',
      children: userData.Group.name,
    },
    {
      key: '7',
      label: 'Group description',
      children: userData.Group.description,
    },
    {
      key: '5',
      label: 'Role',
      children: userData.RoleId,
    },

    {
      key: '6',
      label: 'status',

      children: (
        <>
          <Flex wrap="wrap" gap="small">
            <Tag color={userData.status === 'active' ? 'green' : 'orange'}>
              {userData.status.toUpperCase()}
            </Tag>
          </Flex>
        </>
      ),
    },
  ]
  return (
    <div className="table">
      <Descriptions
        layout="vertical"
        bordered={true}
        column={{ sm: 1, md: 2, lg: 4, xxl: 6 }}
        items={items}
      />
      <div className="centerButton">
        <Button type="primary">
          <Link to="edit" relative="path">
            Edit
          </Link>
        </Button>
      </div>
    </div>
  )
}
export default PatientDescription
