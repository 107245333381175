import CountUp from 'react-countup'
import { Col, Row, Statistic, Skeleton } from 'antd'
import { useFetchStats } from '../../assets/reactQuerry/general'

const formatter = (value) => <CountUp end={value} separator="," />
const App = () => {
  const { data, isLoading } = useFetchStats()
  if (isLoading) {
    return <Skeleton />
  }
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Statistic
          title="Pocet uzivatelu"
          value={data.Users}
          formatter={formatter}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title="Pocet Subjektu"
          value={data.Patients}
          formatter={formatter}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title="Pocet Vzorku"
          value={data.Samples}
          formatter={formatter}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title="Pocet Variant"
          value={data.Variants}
          formatter={formatter}
        />
      </Col>
    </Row>
  )
}
export default App
