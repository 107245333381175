import { useState, useEffect } from 'react';
import { useFetchGroupLogs } from '../../../assets/reactQuerry/general';

import { useFetchGroupMembers } from '../../../assets/reactQuerry/groups';
import { Result, Table, Tag, Skeleton } from 'antd';

const MethodFilter = [
  { text: 'GET', value: 'GET' },
  { text: 'DELETE', value: 'DELETE' },
  { text: 'POST', value: 'POST' },
  { text: 'PATCH', value: 'PATCH' },
];

const methodTranslate = {
  GET: 'viewed',
  DELETE: 'deleted',
  POST: 'added',
  PATCH: 'edited',
};

const methodColorDictionary = {
  GET: 'green',
  POST: 'blue',
  PATCH: 'orange',
  DELETE: 'red',
};

const reorderParams = (params) => {
  const { field, order, pagination, filters } = params;

  const sort =
    field && order
      ? [field, order.toLowerCase() === 'ascend' ? 'ASC' : 'DESC'].join(':')
      : undefined;

  return {
    sort,
    offset: (params.pagination?.current - 1) * params.pagination?.pageSize,
    limit: pagination.pageSize,
    filters:
      filters &&
      Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      ),
  };
};

function GroupLogs() {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        ...pagination, // Override properties passed in pagination
      },
      filters: { ...prevTableParams.filters, ...filters },
      ...sorter,
    }));
  };

  const params = reorderParams(tableParams);
  const { isLoading, isError, data: logs } = useFetchGroupLogs(params);
  const {
    isLoading: isLoadingIds,
    isError: isErrorIds,
    data: groupMembers,
  } = useFetchGroupMembers({ groupId: 'my' });

  useEffect(() => {
    if (logs && logs.count !== tableParams.pagination.total) {
      handleTableChange({
        total: logs.count,
      });
    }
  }, [logs]);

  if (isLoadingIds) return <Skeleton />;

  if (isError)
    return (
      <Result
        status='500'
        title='500'
        subTitle='Omlouvame se, neco se porouchalo'
      />
    );

  const UserFilter = groupMembers.map((user) => {
    return { text: user.fullName, value: user.id };
  });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'User',
      dataIndex: 'UserId',
      render: (text, record) => {
        return <>{record.User.fullName}</>;
      },
      filters: UserFilter,
      onFilter: (value, record) => {
        // console.log(record.UserId)
        // console.log(value)
        return record.UserId === value;
      },
    },
    {
      title: 'Route',
      dataIndex: 'route',
    },
    {
      title: 'Action',
      dataIndex: 'method',
      render: (status) => (
        <Tag color={methodColorDictionary[status]}>
          {methodTranslate[status].toUpperCase()}
        </Tag>
      ),
      filters: MethodFilter,
      onFilter: (value, record) => record.method.indexOf(value) === 0,
    },

    {
      title: 'Old data',
      dataIndex: 'oldData',
      render: (data) => {
        return data
          .replace(/[{}"\\]/g, '')
          .replace(/[:]/g, '=')
          .replace(/[,]/g, ', ');
      },
    },
    {
      title: 'New data',
      dataIndex: 'newData',
      render: (newData) => {
        return newData
          .replace(/[{}"\\]/g, '')
          .replace(/[:]/g, '=')
          .replace(/[,]/g, ', ');
      },
    },
    {
      title: 'When',
      dataIndex: 'createdAt',
      render: (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleString();
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  return (
    <>
      <Table
        loading={isLoading}
        rowKey={(record) => record.id}
        bordered
        dataSource={logs?.rows}
        columns={columns}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
      />
    </>
  );
}
export default GroupLogs;
