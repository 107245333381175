import { RouterProvider } from 'react-router-dom';
import { App } from 'antd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Routes from './assets/Routes';

const Germini = () => {
  return (
    <App>
      <RouterProvider router={Routes()} />
      {/* <RouterProvider router={Routes} /> */}

      <ReactQueryDevtools initialIsOpen={false} />
    </App>
  );
};
export default Germini;
