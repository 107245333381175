import { Form, Table, Typography, Popconfirm, message } from 'antd'
import EditableCell from './EditableCell'

const ExpandedTranscriptsRender = ({
  record,
  subform,
  seteditingSubKey,
  editingSubKey,
  data,
  setData,
}) => {
  const isEditingSub = (record) => record.key === editingSubKey
  const editSub = (record) => {
    subform.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    })
    seteditingSubKey(record.key)
  }
  const cancelSub = () => {
    seteditingSubKey('')
  }
  const saveSub = async (key) => {
    try {
      const subformData = await subform.validateFields()
      const copiedData = [...data]
      const editedRow = copiedData.find((item) => item.key === record.key)
      let subrowData = [...editedRow.subrows]
      const index = subrowData.findIndex((item) => key === item.key)
      if (index > -1) {
        const subrowItem = subrowData[index]
        subrowData.splice(index, 1, {
          ...subrowItem,
          ...subformData,
        })
        const updatedData = copiedData.map((item) =>
          item.key === editedRow.key ? { ...item, subrows: subrowData } : item
        )
        setData(updatedData)
        seteditingSubKey('')
        message.success('Data uploaded') // Display a success message
      } else {
        subrowData.push(subformData)
        setData(subrowData)
        seteditingSubKey('')
        message.success('Data uploaded') // Display a success message
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
      message.error(`Data not uploaded ${errInfo}`) // Display an error message
    }
  }

  const columnsSub = [
    {
      title: 'name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'age',
      dataIndex: 'age',
      width: '15%',
      editable: true,
    },
    {
      title: 'address',
      dataIndex: 'address',
      width: '40%',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditingSub(record)
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveSub(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              saveSub
            </Typography.Link>
            <Popconfirm title="Sure to cancelSub?" onConfirm={cancelSub}>
              <a>cancelSub</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingSubKey !== ''}
            onClick={() => editSub(record)}
          >
            Edit
          </Typography.Link>
        )
      },
    },
  ]

  const mergedcolumnsSub = columnsSub.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingSub(record),
      }),
    }
  })

  return (
    <Form form={subform} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={(() => {
          const row = data.find((item) => item.key === record.key)
          return row.subrows
        })()}
        columns={mergedcolumnsSub}
        rowClassName="editable-row"
        pagination={{
          onChange: cancelSub,
        }}
      />
    </Form>
  )
}

export { ExpandedTranscriptsRender }
