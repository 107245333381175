import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authFetch from '../axios/authFetch';
import { App } from 'antd';

const LIMIT = 20;
const OFFSET = 0;
const SORT = '';
const FILTERS = '';

export const useFetchSampleTags = ({
  offset = OFFSET,
  limit = LIMIT,
  filters = FILTERS,
  sort = SORT,
}) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['sampleTags', limit, offset, sort, filters],
    queryFn: async () => {
      const { data } = await authFetch.get(
        `/sampletags/?limit=${limit}&offset=${offset}&order=${sort}&filters=${JSON.stringify(
          filters
        )}`
      );
      return data;
    },
  });
  return { isLoading, isError, error, data };
};

export const useEditSampleTag = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: editSampleTag } = useMutation({
    mutationFn: ({ tagId, newData }) => {
      return authFetch.patch(`/sampletags/${tagId}`, newData);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['sampleTags'],
      });
      queryClient.invalidateQueries({
        predicate: (query) =>
          Array.isArray(query.queryKey) && query.queryKey[0] === 'samples',
      });
      //   message.success('Sample tag successfully edited');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { editSampleTag };
};

export const useDeleteSampleTag = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutate: deleteSampleTag } = useMutation({
    mutationFn: (tagId) => {
      return authFetch.delete(`/sampletags/${tagId}`);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['sampleTags'],
      });
      message.success('Sample tag was successfully removed');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { deleteSampleTag };
};

export const useAddSampleTag = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const { mutateAsync: addSampleTag } = useMutation({
    mutationFn: ({ newData }) => {
      return authFetch.post(`/sampletags/`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sampleTags'],
      });
      message.success('New tag was created');
    },
    onError: ({
      response: {
        data: { error },
      },
    }) => {
      console.log(error);
      message.error('There was an error :' + error);
    },
  });
  return { addSampleTag };
};
