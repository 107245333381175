import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import {
  Button,
  Skeleton,
  Form,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Result,
} from 'antd';

import {
  useFetchPatients,
  useEditPatient,
  useFetchPatientIds,
} from '../../../assets/reactQuerry/patients';
import authFetch from '../../../assets/axios/authFetch';

const { TextArea } = Input;

// function dataURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(','),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[arr.length - 1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n)
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n)
//   }
//   return new File([u8arr], filename, { type: mime })
// }

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

var formData = new FormData();

const EditPatient = () => {
  let { id } = useParams();
  const { isLoadingIds, patientsIdName } = useFetchPatientIds('all');

  const { editPatient } = useEditPatient();
  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [previewTitle, setPreviewTitle] = useState([]);
  const {
    isLoading,
    isError,
    error,
    data: patientData,
  } = useFetchPatients({ id, full: false });

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!isLoading && patientData?.photo_paths) {
      const imgNames = patientData.photo_paths;
      const ids = Array(imgNames.length).fill(id);

      const fetchData = async () => {
        setFileList([]);
        for (let i = 0; i < imgNames.length; i++) {
          try {
            const response = await authFetch.get(
              `/patients/${ids[i]}/image/${imgNames[i]}`,
              {
                responseType: 'arraybuffer',
              }
            );
            const base64Data = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            const data = {
              uuid: imgNames[i],
              name: imgNames[i],
              thumbUrl: `data:image/png;base64,${base64Data}`,
            };
            setFileList((oldFiles) => [...oldFiles, data]);
          } catch (error) {
            console.error(error);
            message.error(error.message);
          }
        }
      };

      fetchData();
    }
  }, [patientData]);

  if (isLoading || isLoadingIds) {
    return <Skeleton />;
  }

  if (isError) {
    return <Result status='500' title='500' subTitle='Neco se pokazilo' />;
  }

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    file.preview = file.thumbUrl || null;
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };
  const handleChange = async (fileObject) => {
    console.log(fileObject);
    setFileList(fileObject.fileList);
    if (fileObject.file.status === 'removed') {
      try {
        const response = await authFetch.delete(
          `/patients/${id}/image/${fileObject.file.name}`
        );
      } catch (error) {
        console.log(error);
        message.error(error);
      }
    }
  };

  const onFinish = (values) => {
    const filteredEntries = Object.entries(values).filter(
      ([key, value]) => value
    );

    for (const [key, value] of filteredEntries) {
      formData.append(key, value);
    }

    editPatient({ patientId: id, newData: formData });
    formData = new FormData();
    navigate(`../${id}/`);
  };

  return (
    <>
      <Form
        name='editForm'
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout='horizontal'
        initialValues={{
          name: patientData.name,
          motherId: patientData.motherId,
          fatherId: patientData.fatherId,
          sex: patientData.sex,
          phenotype: patientData.phenotype,
        }}
        onFinish={(values) => onFinish(values)}
      >
        <Form.Item name='name' label='Name'>
          <Input />
        </Form.Item>

        <Form.Item name='sex' label='Sex'>
          <Select>
            <Select.Option value='male'>male</Select.Option>
            <Select.Option value='female'>female</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name='motherId' label='Mother'>
          <Select
            showSearch
            placeholder='Choose mother'
            optionFilterProp='children'
          >
            {patientsIdName.map((patient) => {
              return (
                <Select.Option key={patient.value} value={patient.value}>
                  {patient.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name='fatherId' label='Father'>
          <Select
            showSearch
            placeholder='Choose father'
            optionFilterProp='children'
          >
            {patientsIdName.map((patient) => {
              return (
                <Select.Option key={patient.value} value={patient.value}>
                  {patient.label}
                </Select.Option>
              );
            })}{' '}
          </Select>
        </Form.Item>

        <Form.Item name='phenotype' label='Phenotype'>
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label='Photos'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          // name="photos_path"
        >
          <Upload
            // action="http://localhost:8080/api/v1/patients/11/upload"
            beforeUpload={(file) => {
              const isImage = file.type.startsWith('image');
              if (!isImage) {
                message.error(`${file.name} is not an image`);
              }
              return isImage || Upload.LIST_IGNORE;
            }}
            customRequest={async (options) => {
              console.log(options);
              const { onSuccess, onError, file, onProgress } = options;
              try {
                const formData = new FormData();
                formData.append('file', file);
                const config = {
                  headers: { 'content-type': 'multipart/form-data' },
                  onUploadProgress: (event) => {
                    console.log((event.loaded / event.total) * 100);
                    onProgress(
                      { percent: (event.loaded / event.total) * 100 },
                      file
                    );
                  },
                };
                const response = await authFetch.post(
                  `/patients/${id}/image`,
                  formData,
                  config
                );
                onSuccess(file);
              } catch (err) {
                console.log(err);
                const error = new Error(err.response.statusText);
                message.error(err.response.data);
                onError(error);
              }
            }}
            method='post'
            listType='picture-card'
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
          {/* <Upload
            fileList={fileList}
            // fileList={patients.imgs}
            onPreview={handlePreview}
            onChange={handleChange}
            beforeUpload={(file) => {
              setFiles([...files, file])
              console.log(files)

              // const index = fileArray.length
              // formData.append(`files[${index}]`, file)
              // fileArray.push(file)

              // // Optionally, you can do something else after each file is appended
              // console.log(`Appended file[${index}] to FormData`)
              // console.log(file)
              return false
            }}
            // onRemove={(file) => {
            //   console.log(file)
            //   const index = files.indexOf(file)
            //   const newFiles = files.slice()
            //   newFiles.splice(index, 1)
            //   setFiles(newFiles)
            // }}
            listType="picture-card"
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload> */}
        </Form.Item>
        <Form.Item style={{ width: '200px', margin: '0 auto' }}>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
          >
            Edit
          </Button>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt='example'
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default EditPatient;

// import React, { useState } from 'react'
// import { PlusOutlined } from '@ant-design/icons'
// import { Modal, Upload } from 'antd'
// const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// const App = () => {
//   const [previewOpen, setPreviewOpen] = useState(false)
//   const [previewImage, setPreviewImage] = useState('')
//   const [previewTitle, setPreviewTitle] = useState('')
//   const [fileList, setFileList] = useState([
//     // {
//     //   uid: '-1',
//     //   name: 'image.png',
//     //   status: 'done',
//     //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     // },
//     // {
//     //   uid: '-2',
//     //   name: 'image.png',
//     //   status: 'done',
//     //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     // },
//     // {
//     //   uid: '-3',
//     //   name: 'image.png',
//     //   status: 'done',
//     //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     // },
//     // {
//     //   uid: '-4',
//     //   name: 'image.png',
//     //   status: 'done',
//     //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     // },
//     // {
//     //   uid: '-xxx',
//     //   percent: 50,
//     //   name: 'image.png',
//     //   status: 'uploading',
//     //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     // },
//     // {
//     //   uid: '-5',
//     //   name: 'image.png',
//     //   status: 'error',
//     // },
//   ])
//   const handleCancel = () => setPreviewOpen(false)
//   const handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj)
//     }
//     setPreviewImage(file.url || file.preview)
//     setPreviewOpen(true)
//     setPreviewTitle(
//       file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
//     )
//   }
//   const handleChange = (fileList) => {
//     console.log('handleChange')
//     console.log(fileList)
//     setFileList(fileList.fileList)
//     console.log(fileList)
//   }
//   const uploadButton = (
//     <div>
//       <PlusOutlined />
//       <div
//         style={{
//           marginTop: 8,
//         }}
//       >
//         Upload
//       </div>
//     </div>
//   )
//   return (
//     <>
//       <Upload
//         // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
//         listType="picture-card"
//         fileList={fileList}
//         onPreview={handlePreview}
//         onChange={handleChange}
//         beforeUpload={(file) => {
//           console.log(file)
//           //   setFileList([...fileList, file])
//           // const index = fileArray.length
//           // formData.append(`files[${index}]`, file)
//           // fileArray.push(file)

//           // // Optionally, you can do something else after each file is appended
//           // console.log(`Appended file[${index}] to FormData`)
//           // console.log(file)
//           return false
//         }}
//       >
//         {fileList.length >= 8 ? null : uploadButton}
//       </Upload>
//       <Modal
//         open={previewOpen}
//         title={previewTitle}
//         footer={null}
//         onCancel={handleCancel}
//       >
//         <img
//           alt="example"
//           style={{
//             width: '100%',
//           }}
//           src={previewImage}
//         />
//       </Modal>
//     </>
//   )
// }
// export default App
